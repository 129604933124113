import React from 'react';

import { dateToString } from '../../../utils/dates';

import './styles.css';

const DateInput = ({ label, date, width, minimum, maximum, onDateChange }) => {
    const widthClass = width ? `date-input-width-${width}` : '';

    return (
        <div className='date-input-wrapper'>
            <input
                type='date'
                className={`date-input ${widthClass}`}
                min={minimum ? minimum.toISOString().split('T')[0] : null}
                max={maximum ? maximum.toISOString().split('T')[0] : null}
                value={date ? dateToString(date) : ''}
                onChange={onDateChange}
            />
            {label && <span className='date-input-label'>{label}</span>}
        </div>
    );
};

export default DateInput;
