import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import CalculatorContext from '../../../contexts/CalculatorContext';

import HeadingOne from '../../Heading/One';
import HeadingFour from '../../Heading/Four';
import IconButton from '../../Button/Icon';
import Paragraph from '../../Paragraph';

import PregnantWomanImage from '../../../assets/photos/pregnancy-results.png';
import { formatDate } from '../../../utils/dates';
import { RESULTS } from '../../../constants/routes';

import './styles.css';

const CalculatorResultsView = () => {
    const { results } = useContext(CalculatorContext);
    const navigate = useNavigate();

    return (
        <div id='calculator-results-view'>
            <main id='calculator-results-text'>
                <HeadingFour color='blue-light'>
                    Seu bebê provavelmente vai nascer entre
                </HeadingFour>
                <HeadingOne color='lime'>{`${
                    formatDate(results['Term (Start)']) || ''
                } e ${formatDate(results['Term (End)']) || ''}`}</HeadingOne>
                <Paragraph color='white-light'>
                    Em vez de focar em um dia específico como a data provável de
                    nascimento de sua criança, é muito mais adequado conhecer o
                    período de tempo em que o trabalho de parto provavelmente
                    iniciará de maneira espontânea. Continue lendo para entender
                    os motivos!
                </Paragraph>
                <IconButton
                    border='purple'
                    fill='dark'
                    icon='lime-down-arrow'
                    position='right'
                    text='clique aqui para entender esse resultado'
                    width='full'
                    onClick={() => navigate(RESULTS)}
                />
            </main>
            <div id='calculator-results-image'>
                <img src={PregnantWomanImage} alt='' />
            </div>
        </div>
    );
};

export default CalculatorResultsView;
