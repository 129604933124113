import React from 'react';
import { useNavigate } from 'react-router-dom';

import Paragraph from '../../components/Paragraph';
import RegularButton from '../../components/Button/Regular';

import logoImage from '../../assets/logo.png';
import topFlower from '../../assets/flowers/home-flower-1.png';
import leftFlower from '../../assets/flowers/home-flower-2.png';
import rightFlower from '../../assets/flowers/home-flower-3.png';
import { CALCULATOR, EXTRAS } from '../../constants/routes';

import './styles.css';

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <div id='home-page'>
            <div id='home-page-left-flower'>
                <img src={leftFlower} alt='' />
            </div>
            <main id='home-page-content'>
                <div id='home-page-top-flower'>
                    <img src={topFlower} alt='' />
                </div>
                <div id='home-page-logo'>
                    <img src={logoImage} alt='Contando os dias' />
                </div>
                <section id='home-page-text'>
                    <Paragraph align='center' color='dark'>
                        Arrumar a mala da maternidade, lavar as roupinhas, parar
                        de trabalhar. Independentemente de quais preparativos
                        sua vida exigir, você deve estar{' '}
                        <span className='weight-900'>
                            contando os dias para ter o seu bebê nos braços
                        </span>
                        .
                    </Paragraph>
                    <Paragraph align='center' color='dark'>
                        Aqui você encontra uma calculadora que ajuda a entender{' '}
                        <span className='weight-900'>
                            quando o seu bebê provavelmente vai nascer
                        </span>{' '}
                        e explica por que{' '}
                        <span className='weight-900'>
                            cada dia que ele passa na sua barriga conta para um
                            nascimento mais seguro e uma vida mais saudável
                        </span>
                        .
                    </Paragraph>
                </section>
                <section id='home-page-buttons'>
                    <RegularButton
                        fill='lime'
                        text='acesse a calculadora do nascimento'
                        onClick={() => navigate(CALCULATOR)}
                    />
                    <RegularButton
                        fill='none'
                        text='ou entenda por que cada dia conta'
                        onClick={() => navigate(EXTRAS)}
                    />
                </section>
            </main>
            <div id='home-page-right-flower'>
                <img src={rightFlower} alt='' />
            </div>
        </div>
    );
};

export default HomePage;
