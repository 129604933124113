import React from 'react';

import HeadingThree from '../../Heading/Three';
import Paragraph from '../../Paragraph';

import PregnantWomanImage from '../../../assets/photos/pregnancy-importance.png';

import './styles.css';

const ExtrasIntroView = () => {
    return (
        <div id='extras-intro-view'>
            <aside id='extras-intro-image'>
                <img src={PregnantWomanImage} alt='' />
            </aside>
            <section id='extras-intro-content'>
                <HeadingThree color='pink'>
                    A importância de entrar em trabalho de parto
                </HeadingThree>
                <Paragraph color='dark'>
                    Ao contrário do que se acreditava até recentemente,{' '}
                    <span className='weight-900'>
                        não é porque uma gravidez chegou ao período do termo (37
                        semanas completas) que o bebê está, necessariamente,
                        pronto para nascer
                    </span>
                    .
                </Paragraph>
                <Paragraph color='dark'>
                    Enquanto alguns bebês vão estar prontos para nascer com
                    apenas 37 semanas, outros vão precisar ficar até 41 semanas
                    na barriga. E não é só por causa de tamanho ou peso. Entram
                    questões como maturidade respiratória, por exemplo, e hoje
                    sabemos que{' '}
                    <span className='weight-900'>
                        nenhum exame detecta isso de maneira adequada
                    </span>
                    .
                </Paragraph>
                <Paragraph color='dark'>
                    Mas sabemos que um dos{' '}
                    <span className='weight-900'>
                        principais indicadores da maturidade do bebê para nascer
                    </span>{' '}
                    é sua mãe{' '}
                    <span className='color-pink'>
                        entrar em trabalho de parto espontaneamente, sem
                        intervenções
                    </span>
                    . Em uma gravidez de risco normal, esse é o{' '}
                    <span className='weight-900'>
                        momento certo e mais saudável para o bebê nascer
                    </span>
                    .
                </Paragraph>
            </section>
        </div>
    );
};

export default ExtrasIntroView;
