import React from 'react';

import HeadingFive from '../../Heading/Five';
import Paragraph from '../../Paragraph';

import PregnantWomanImage from '../../../assets/photos/pregnancy-findings.png';

import './styles.css';

const ExtrasFindingsView = () => {
    return (
        <div id='extras-findings-view'>
            <aside id='extras-findings-image'>
                <img src={PregnantWomanImage} alt='' />
            </aside>
            <section id='extras-findings-content'>
                <section id='extras-findings-title'>
                    <HeadingFive color='dark'>
                        Esses dias de gestação perdidos pelo bebê têm impactos
                        importantes na saúde da mãe e da criança:
                    </HeadingFive>
                </section>
                <section id='extras-findings-text'>
                    <Paragraph color='green' size='medium' weight='600'>
                        Nascer antes do tempo{' '}
                        <span className='weight-900'>
                            pode afetar o desenvolvimento do bebê, inclusive no
                            longo prazo
                        </span>
                        .{' '}
                        <a
                            className='superscript'
                            href='https://www.sciencedirect.com/science/article/abs/pii/S0140673618319305'
                            target='__blank'
                        >
                            [1]
                        </a>
                    </Paragraph>
                    <Paragraph color='green' size='medium' weight='500'>
                        Nascer antes do tempo aumenta as chances de o bebê ter{' '}
                        <span className='weight-900'>
                            doenças respiratórias
                        </span>
                        .{' '}
                        <a
                            className='superscript'
                            href='https://obgyn.onlinelibrary.wiley.com/doi/full/10.1111/aogs.13644'
                            target='__blank'
                        >
                            [2]
                        </a>
                    </Paragraph>
                    <Paragraph color='green' size='medium' weight='500'>
                        Cesáreas desnecessárias aumentam as chances de{' '}
                        <span className='weight-900'>
                            complicações de saúde nas mães
                        </span>
                        .{' '}
                        <a
                            className='superscript'
                            href='https://www.sciencedirect.com/science/article/abs/pii/S0140673618323869'
                            target='__blank'
                        >
                            [3]
                        </a>
                    </Paragraph>
                    <Paragraph color='green' size='medium' weight='500'>
                        Cesáreas desnecessárias, levando a mais dias de gravidez
                        perdidos,{' '}
                        <span className='weight-900'>
                            aumentam as chances de morte dos bebês
                        </span>
                        .{' '}
                        <a
                            className='superscript'
                            href='https://journals.plos.org/plosone/article/comments?id=10.1371/journal.pone.0277833'
                            target='__blank'
                        >
                            [4]
                        </a>
                    </Paragraph>
                </section>
            </section>
        </div>
    );
};

export default ExtrasFindingsView;
