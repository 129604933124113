import * as d3 from 'd3';

export const createCategoricalScale = (domain, range) => {
    return d3.scaleBand().domain(domain).range(range);
};

export const createLinearScale = (domain, range) => {
    return d3.scaleLinear().domain(domain).range(range).nice();
};

export const createSqrtScale = (domain, range) => {
    return d3.scaleSqrt().domain(domain).range(range).nice();
};

export const createLinearTransition = (duration, delay) =>
    d3
        .transition()
        .delay(delay || 0)
        .duration(duration)
        .ease(d3.easeLinear);

export const createHorizontalLink = (x1, y1, x2, y2) => {
    const nodes = { source: { x: x1, y: y1 }, target: { x: x2, y: y2 } };

    return d3
        .linkHorizontal()
        .x((d) => d.x)
        .y((d) => d.y)(nodes);
};

export const getDayBarPosition = (day, barData, config, column) => {
    const bars = barData.filter((other) => other.day >= day);
    let barsWidth = 0;

    ['line', 'large', 'wide'].forEach((type) => {
        barsWidth +=
            bars.filter((other) => other[column] === type).length *
            config.bars[type];
    });

    barsWidth += bars.length * config.bars.line;

    return (
        config.width / 2 + config.bars.area / 2 - barsWidth - config.bars.line
    );
};

export const getGroupBarWidth = (barData, config) =>
    config.bars.line * (barData.line + barData.large + barData.wide) +
    config.bars.line * barData.line +
    config.bars.large * barData.large +
    config.bars.wide * barData.wide;

export const getGroupBarPosition = (barData, config, data, type) => {
    const width = getGroupBarWidth(barData, config) - config.bars.line;

    const others = data.filter((o) => o[type] > barData[type]);
    const othersWidth = others
        .map((otherBar) => getGroupBarWidth(otherBar, config))
        .reduce((a, b) => a + b, 0);

    return (
        config.width / 2 +
        config.bars.area / 2 -
        othersWidth -
        width -
        config.bars.line * 2
    );
};

export const getMonthData = (data, column) => {
    const months = Array.from({ length: 10 }, (v, k) => k + 1);
    return months.map((m) => ({
        month: m,
        line: data
            .filter((d) => d.month === m)
            .filter((d) => d[column] === 'line').length,
        large: data
            .filter((d) => d.month === m)
            .filter((d) => d[column] === 'large').length,
        wide: data
            .filter((d) => d.month === m)
            .filter((d) => d[column] === 'wide').length,
    }));
};

export const getWeekData = (data, column) => {
    const weeks = Array.from({ length: 43 }, (v, k) => k);
    return weeks.map((w) => ({
        week: w,
        line: data
            .filter((d) => d.week === w)
            .filter((d) => d[column] === 'line').length,
        large: data
            .filter((d) => d.week === w)
            .filter((d) => d[column] === 'large').length,
        wide: data
            .filter((d) => d.week === w)
            .filter((d) => d[column] === 'wide').length,
    }));
};

export const rotateSquareSymbol = (x, y) => `translate(${x}, ${y}) rotate(45)`;
