import React, { useState, useContext } from 'react';

import CalculatorContext from '../../../../contexts/CalculatorContext';

import Paragraph from '../../../Paragraph';
import RegularButton from '../../../Button/Regular';

import './styles.css';

const CalculatorFormHint = () => {
    const [hideHint, setHideHint] = useState(false);

    const { method } = useContext(CalculatorContext);

    return hideHint || method !== 'Last Period Date' ? (
        <></>
    ) : (
        <div id='calculator-form-hint-wrapper'>
            <aside id='calculator-form-hint'>
                <Paragraph color='pink' size='very-small'>
                    A ovulação acontece cerca de 14 dias antes do começo do
                    próximo ciclo menstrual.
                </Paragraph>
                <Paragraph color='pink' size='very-small'>
                    Preencha a informação que você conhece com maior confiança e
                    calcularemos automaticamente o resto.
                </Paragraph>
                <RegularButton
                    fill='lime'
                    text='fechar'
                    onClick={() => setHideHint(true)}
                />
            </aside>
        </div>
    );
};

export default CalculatorFormHint;
