import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

import useCalculatorState from '../hooks/useCalculatorState';
import CalculatorContext from '../contexts/CalculatorContext';

import HomePage from '../scenes/HomePage';
import CalculatorPage from '../scenes/CalculatorPage';
import ResultsPage from './ResultsPage';
import ExtrasPage from '../scenes/ExtrasPage';
import BlogPage from './BlogPage';
import ReferencesPage from './ReferencesPage';

import * as routes from '../constants/routes';
import '../constants/root.css';
import '../constants/text.css';
import '../constants/colors.css';

const App = () => {
    const calculatorState = useCalculatorState();

    return (
        <BrowserRouter>
            <CalculatorContext.Provider value={calculatorState}>
                <Routes>
                    <Route path={routes.HOME} element={<HomePage />} />
                    <Route
                        path={routes.CALCULATOR}
                        element={<CalculatorPage />}
                    />
                    <Route path={routes.RESULTS} element={<ResultsPage />} />
                    <Route path={routes.EXTRAS} element={<ExtrasPage />} />
                    <Route path={routes.BLOG} element={<BlogPage />} />
                    <Route
                        path={routes.REFERENCES}
                        element={<ReferencesPage />}
                    />
                    <Route
                        path='*'
                        element={<Navigate replace to={routes.HOME} />}
                    />
                </Routes>
            </CalculatorContext.Provider>
        </BrowserRouter>
    );
};

export default App;
