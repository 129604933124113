import { useState, useEffect } from 'react';

import { changeDateByDays, getDaysBetweenDates } from '../utils/dates';

const useCalculatorState = () => {
    const [step, setStep] = useState('introduction');
    const [method, setMethod] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [formFilled, setFormFilled] = useState(false);
    const [entries, setEntries] = useState({
        'Period': null,
        'Cycle': 28,
        'Ovulation': null,
        'First Exam': null,
        'G. Age (W)': 0,
        'G. Age (D)': 1,
        'Min. Ovulation': null,
        'Max. Ovulation': null,
    });
    const [results, setResults] = useState({
        'Delay': null,
        'Term (Start)': null,
        'Term (End)': null,
        'Birth': null,
        'G. Age': null,
        'Exam Limit': null,
    });

    const toggleDetails = () => setShowDetails(!showDetails);

    const changeEntries = (key, value) => {
        const currentEntries = { ...entries };
        currentEntries[key] = value;

        // Check number input limits
        if (key === 'Cycle')
            currentEntries[key] = Math.min(Math.max(value, 21), 35);
        if (key === 'G. Age (W)')
            currentEntries[key] = Math.min(Math.max(value, 0), 42);
        if (key === 'G. Age (D)')
            currentEntries[key] = Math.min(Math.max(value, 0), 6);

        // Fill missing entries (Method: First Exam Date)
        if (
            method === 'First Exam Date' &&
            (entries['First Exam'] || key === 'First Exam')
        ) {
            const pregnancyDays =
                currentEntries['G. Age (W)'] * 7 + currentEntries['G. Age (D)'];

            currentEntries['Period'] = changeDateByDays(
                currentEntries['First Exam'],
                1,
                pregnancyDays
            );
            currentEntries['Ovulation'] = changeDateByDays(
                currentEntries['Period'],
                currentEntries['Cycle'],
                14
            );
        }

        // Fill missing entries with no details (Method: Last Period Date)
        else if (
            method === 'Last Period Date' &&
            !showDetails &&
            (entries['Period'] || key === 'Period')
        ) {
            currentEntries['Ovulation'] = changeDateByDays(
                currentEntries['Period'],
                currentEntries['Cycle'],
                14
            );
            currentEntries['Min. Ovulation'] = changeDateByDays(
                currentEntries['Period'],
                currentEntries['Cycle'],
                21
            );
            currentEntries['Max. Ovulation'] = changeDateByDays(
                currentEntries['Period'],
                currentEntries['Cycle'],
                7
            );
        }

        // Fill missing entries with details (Method: Last Period Date)
        else if (method === 'Last Period Date' && showDetails) {
            if (key === 'Cycle') {
                if (!entries['Period'] && entries['Ovulation']) {
                    currentEntries['Period'] = changeDateByDays(
                        currentEntries['Ovulation'],
                        14,
                        currentEntries['Cycle']
                    );
                }

                if (entries['Period']) {
                    currentEntries['Ovulation'] = changeDateByDays(
                        currentEntries['Period'],
                        currentEntries['Cycle'],
                        14
                    );
                    currentEntries['Min. Ovulation'] = changeDateByDays(
                        currentEntries['Period'],
                        currentEntries['Cycle'],
                        21
                    );
                    currentEntries['Max. Ovulation'] = changeDateByDays(
                        currentEntries['Period'],
                        currentEntries['Cycle'],
                        7
                    );
                }
            }

            if (key === 'Ovulation') {
                if (!entries['Period']) {
                    currentEntries['Period'] = changeDateByDays(
                        currentEntries['Ovulation'],
                        14,
                        currentEntries['Cycle']
                    );
                }

                if (entries['Period']) {
                    currentEntries['Cycle'] =
                        getDaysBetweenDates(
                            currentEntries['Ovulation'],
                            currentEntries['Period']
                        ) + 14;
                }
            }

            if (key === 'Period') {
                currentEntries['Ovulation'] = changeDateByDays(
                    currentEntries['Period'],
                    currentEntries['Cycle'],
                    14
                );
                currentEntries['Min. Ovulation'] = changeDateByDays(
                    currentEntries['Period'],
                    currentEntries['Cycle'],
                    21
                );
                currentEntries['Max. Ovulation'] = changeDateByDays(
                    currentEntries['Period'],
                    currentEntries['Cycle'],
                    7
                );
            }
        }

        if (entries !== currentEntries) setEntries(currentEntries);
    };

    useEffect(() => {
        const examMethod =
            method === 'First Exam Date' && entries['First Exam'];

        const periodMethod =
            method === 'Last Period Date' && !showDetails && entries['Period'];

        const periodDetailedMethod =
            method === 'Last Period Date' &&
            showDetails &&
            entries['Period'] &&
            entries['Ovulation'];

        setFormFilled(examMethod || periodMethod || periodDetailedMethod);
    }, [method, entries, showDetails]);

    useEffect(() => {
        if (formFilled) {
            const currentResults = {};

            currentResults['Birth'] = changeDateByDays(
                entries['Ovulation'],
                265
            );
            currentResults['Delay'] = changeDateByDays(
                entries['Ovulation'],
                14
            );
            currentResults['Exam Limit'] = changeDateByDays(
                entries['Ovulation'],
                82
            );
            currentResults['Term (Start)'] = changeDateByDays(
                entries['Ovulation'],
                244
            );
            currentResults['Term (End)'] = changeDateByDays(
                entries['Ovulation'],
                278
            );
            currentResults['G. Age'] = getDaysBetweenDates(
                new Date(),
                entries['Period']
            );

            setResults(currentResults);
        }
    }, [entries, formFilled]);

    useEffect(() => {
        if (step === 'introduction') {
            setMethod(null);
            setShowDetails(false);
        }
    }, [step]);

    return {
        step,
        method,
        showDetails,
        entries,
        results,
        setStep,
        setMethod,
        toggleDetails,
        changeEntries,
    };
};

export default useCalculatorState;
