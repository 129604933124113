import React, { useContext } from 'react';

import CalculatorContext from '../../../../contexts/CalculatorContext';

import DateInput from '../../../Input/Date';
import NumberInput from '../../../Input/Number';
import Paragraph from '../../../Paragraph';
import RegularButton from '../../../Button/Regular';

import { changeDateByHours } from '../../../../utils/dates';

import './styles.css';

const CalculatorFormInputs = () => {
    const { method, showDetails, entries, toggleDetails, changeEntries } =
        useContext(CalculatorContext);

    return (
        <section id='calculator-form-inputs'>
            {method === 'Last Period Date' ? (
                <DateInput
                    label={'Inserir a data da última menstruação'}
                    date={entries['Period']}
                    width='full'
                    onDateChange={(e) =>
                        changeEntries(
                            'Period',
                            changeDateByHours(new Date(e.target.value), 3)
                        )
                    }
                />
            ) : (
                <>
                    <DateInput
                        label={'Inserir a data do primeiro ultrassom'}
                        date={entries['First Exam']}
                        width='full'
                        onDateChange={(e) =>
                            changeEntries(
                                'First Exam',
                                changeDateByHours(new Date(e.target.value), 3)
                            )
                        }
                    />
                    <div id='gestational-age-inputs'>
                        <NumberInput
                            minimum={0}
                            maximum={42}
                            sideText='semanas e'
                            value={entries['G. Age (W)']}
                            onValueChange={(e) =>
                                changeEntries(
                                    'G. Age (W)',
                                    parseInt(e.target.value)
                                )
                            }
                        />
                        <NumberInput
                            minimum={0}
                            maximum={6}
                            sideText='dias'
                            value={entries['G. Age (D)']}
                            onValueChange={(e) =>
                                changeEntries(
                                    'G. Age (D)',
                                    parseInt(e.target.value)
                                )
                            }
                        />
                        <span className='number-input-label'>
                            Inserir a idade gestacional
                        </span>
                    </div>
                </>
            )}

            {method === 'Last Period Date' && !showDetails && (
                <>
                    <RegularButton
                        border='blue-dark'
                        fill={entries['Period'] ? 'white' : 'disable'}
                        width='full'
                        text='deixar o cálculo mais preciso (opcional)'
                        onClick={() =>
                            entries['Period'] ? toggleDetails() : {}
                        }
                    />
                    <Paragraph
                        align='center'
                        color='dark'
                        decoration='italic'
                        size='very-small'
                    >
                        Deixe o cálculo mais preciso usando também a duração do
                        meu ciclo menstrual ou a data da minha ovulação.
                    </Paragraph>
                </>
            )}

            {method === 'Last Period Date' && showDetails && (
                <>
                    <DateInput
                        label='Inserir a data da ovulação'
                        date={entries['Ovulation']}
                        width='full'
                        minimum={entries['Min. Ovulation']}
                        maximum={entries['Max. Ovulation']}
                        onDateChange={(e) =>
                            changeEntries(
                                'Ovulation',
                                changeDateByHours(new Date(e.target.value), 3)
                            )
                        }
                    />
                    <NumberInput
                        label='Inserir a duração do seu ciclo'
                        minimum={21}
                        maximum={35}
                        sideText='dias'
                        value={entries['Cycle']}
                        onValueChange={(e) =>
                            changeEntries(
                                'Cycle',
                                parseInt(Math.max(e.target.value, 1))
                            )
                        }
                    />
                </>
            )}
        </section>
    );
};

export default CalculatorFormInputs;
