import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

import Paragraph from '../../Paragraph';

import { createLinearScale, createSqrtScale } from '../../../utils/charts';
import * as chart from '../../../utils/birthsByTypeChart';
import data from '../../../data/distributionCharts.json';

import './styles.css';

const chartConfig = {
    height: 700,
    width: 1152,
    margin: { top: 50, right: 250, bottom: 25, left: 75 },
    bars: { area: 625, line: 1.5, large: 22.5, wide: 45 },
    types: ['CPTP', 'CIP', 'VI', 'VNI'],
    baseHeights: { CPTP: 55, CIP: 165, VI: 275, VNI: 385 },
};

const yDomain = [0.005, d3.max(data, (d) => d.value)];
const yRange = [225, 0];
chartConfig.yScale = createLinearScale(yDomain, yRange);

const radiusDomain = [0, 100];
const radiusRange = [5, 60];
chartConfig.radiusScale = createSqrtScale(radiusDomain, radiusRange);

const ExtrasBirthsByTypeView = () => {
    const svgReference = useRef(null);

    useEffect(() => {
        const svg = d3.select(svgReference.current);
        chart.createChart(svg, chartConfig, data);
    }, []);

    return (
        <div id='extras-births-by-type-view'>
            <header
                id='births-by-type-chart-title'
                title='Dados de nascimentos no Brasil em 2019, obtidos a partir do SINASC (Sistema de Informação sobre Nascidos Vivos)'
            >
                Gráfico: Nascimentos no Brasil por dia de gestação e tipo de
                parto
            </header>
            <section id='births-by-type-chart-content'>
                <svg
                    id='births-by-type-chart-svg'
                    ref={svgReference}
                    viewBox={[[0, 0, chartConfig.width, chartConfig.height]]}
                />
                <div id='births-by-type-chart-side-text'>
                    <Paragraph color='dark'>
                        Quando olhamos para as{' '}
                        <span className='color-birth-type-pink'>
                            diferentes
                        </span>
                        <span className='color-birth-type-orange'> formas</span>
                        <span className='color-birth-type-purple'>
                            {' '}
                            de nascer
                        </span>
                        <span className='color-birth-type-blue'>
                            {' '}
                            no Brasil
                        </span>
                        , e como cada uma tem uma distribuição diferente de
                        nascimentos em relação à DPP, fica claro que o excesso
                        de intervenções está fazendo com que muitos bebês nasçam
                        mais cedo do que deveriam.
                    </Paragraph>
                    <Paragraph color='dark'>
                        Ou seja: muitos bebês estão perdendo dias de gestação.
                    </Paragraph>
                </div>
            </section>
            <section id='births-by-type-chart-mb-text'>
                <span>
                    Os bebês podem nascer por{' '}
                    <span className='color-birth-type-pink weight-700'>
                        cesariana sem trabalho de parto
                    </span>
                    ,{' '}
                    <span className='color-birth-type-orange weight-700'>
                        cesariana após trabalho de parto
                    </span>
                    ,{' '}
                    <span className='color-birth-type-purple weight-700'>
                        parto vaginal após indução
                    </span>{' '}
                    ou{' '}
                    <span className='color-birth-type-blue weight-700'>
                        parto vaginal sem indução
                    </span>
                    . Porém, no Brasil, quando olhamos a distribuição dos
                    nascimentos em relação à DPP, fica claro que o excesso de
                    intervenções está fazendo com que muitos bebês nasçam mais
                    cedo do que deveriam.
                </span>
                <span>
                    Ou seja: muitos bebês estão perdendo dias de gestação.
                </span>
            </section>
        </div>
    );
};

export default ExtrasBirthsByTypeView;
