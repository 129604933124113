import { createContext } from 'react';

const CalculatorContext = createContext({
    step: 'introduction',
    method: null,
    showDetails: false,
    entries: {
        'Period': null,
        'Cycle': 28,
        'Ovulation': null,
        'First Exam': null,
        'G. Age (W)': 0,
        'G. Age (D)': 1,
        'Min. Ovulation': null,
        'Max. Ovulation': null,
    },
    results: {
        'Delay': null,
        'Term (Start)': null,
        'Term (End)': null,
        'Birth': null,
        'G. Age': null,
        'Exam Limit': null,
    },
    setStep: () => {},
    setMethod: () => {},
    toggleDetails: () => {},
    changeEntries: () => {},
});

export default CalculatorContext;
