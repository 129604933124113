import * as utils from './charts';

const addDayBars = (svg, config, data) => {
    const bars = svg.append('g').attr('id', 'births-by-day-chart-bars');

    config.types.forEach((t) => {
        const type = t.toLowerCase();
        bars.selectAll(`.births-by-type-chart-${type}-bars`)
            .data(data)
            .enter()
            .append('rect')
            .attr('class', `births-by-type-chart-${type}-bar`)
            .attr('x', (d) =>
                utils.getDayBarPosition(d.day, data, config, 'width')
            )
            .attr(
                'y',

                (d) =>
                    d.expected === -1
                        ? config.yScale(0) + config.baseHeights[t] - 20
                        : config.yScale(d[`births${t}`]) + config.baseHeights[t]
            )
            .attr(
                'height',

                (d) =>
                    d.expected === -1
                        ? 20
                        : config.yScale(0) - config.yScale(d[`births${t}`])
            )
            .attr('width', (d) => config.bars[d.width]);
    });
};

const addTypeCircles = (svg, config) => {
    const content = [
        { type: 'CPTP', percentage: 27 },
        { type: 'CIP', percentage: 29 },
        { type: 'VI', percentage: 9 },
        { type: 'VNI', percentage: 34 },
    ];

    content.forEach((c) =>
        svg
            .append('circle')
            .attr(
                'class',
                `births-by-type-chart-circle-${c.type.toLowerCase()}`
            )
            .attr('cx', config.width - config.margin.right + 25)
            .attr('cy', config.baseHeights[c.type] + 220)
            .attr('r', config.radiusScale(c.percentage))
    );

    svg.append('text')
        .attr('class', 'births-by-type-chart-text-cptp')
        .attr('y', 280)
        .html(
            `<tspan x="918">27%<tspan class='complementary-text'> de cesariana</tspan></tspan>
            <tspan x="918" dy="1.05em"><tspan class='complementary-text'>sem trabalho de parto</tspan></tspan>`
        );

    svg.append('text')
        .attr('class', 'births-by-type-chart-text-cip')
        .attr('y', 390)
        .html(
            `<tspan x="918">29%<tspan class='complementary-text'> de cesariana</tspan></tspan>
            <tspan x="918" dy="1.05em"><tspan class='complementary-text'>após trabalho de parto</tspan></tspan>`
        );

    svg.append('text')
        .attr('class', 'births-by-type-chart-text-vi')
        .attr('y', 500)
        .html(
            `<tspan x="918">9%<tspan class='complementary-text'> de parto vaginal</tspan></tspan>
            <tspan x="918" dy="1.05em"><tspan class='complementary-text'>após indução</tspan></tspan>`
        );

    svg.append('text')
        .attr('class', 'births-by-type-chart-text-vni')
        .attr('y', 610)
        .html(
            `<tspan x="918">34%<tspan class='complementary-text'> de parto vaginal</tspan></tspan>
            <tspan x="918" dy="1.05em"><tspan class='complementary-text'>sem indução</tspan></tspan>`
        );
};

const addDayMarkers = (svg, config, data) => {
    const texts = ['259', 'DPP (280 dias)', '293'];

    const days = [259, 280, 293];
    const positions = days.map(
        (day) =>
            utils.getDayBarPosition(day, data, config, 'width') +
            config.bars.large / 2
    );

    texts.forEach((text, i) => {
        svg.append('line')
            .attr('class', 'day-marker-line')
            .attr('x1', positions[i])
            .attr('x2', positions[i])
            .attr('y1', config.height - 43)
            .attr('y2', 0);

        svg.append('text')
            .attr('class', 'day-marker-text')
            .attr('x', positions[i])
            .attr('y', config.height - 23)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .text(text);
    });
};

export const createChart = (svg, config, data) => {
    svg.selectAll('*').remove();

    addDayBars(svg, config, data);
    addDayMarkers(svg, config, data);

    addTypeCircles(svg, config);
};
