import React from 'react';

import './styles.css';

const ResultsChartTitle = ({ step, showToday }) => {
    const showTitle = (showToday && step >= 14) || (!showToday && step >= 13);

    return (
        <header
            id='results-chart-title'
            title='Dados de nascimentos no Brasil em 2019, obtidos a partir do SINASC (Sistema de Informação sobre Nascidos Vivos)'
        >
            {!showTitle && <span>&nbsp;</span>}
            {showTitle && (
                <span>
                    Gráfico: Nascimentos a termo por dia de gestação{' '}
                    <span className='highlight-text-birth-type-blue'>
                        (trabalho de parto com início espontâneo)
                    </span>
                </span>
            )}
        </header>
    );
};

export default ResultsChartTitle;
