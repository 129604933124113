import React from 'react';

import './styles.css';

const LinkGallery = ({ items }) => {
    return (
        <div className='link-gallery'>
            {items.map((item) => (
                <a
                    key={item.key}
                    href={item.link}
                    target='_blank'
                    rel='noreferrer'
                >
                    <article className='link-gallery-item'>
                        <img
                            className='link-gallery-item-image'
                            src={item.image}
                            alt=''
                        />
                        <div className='link-gallery-item-text'>
                            <span>{item.text || ''}</span>
                        </div>
                    </article>
                </a>
            ))}
        </div>
    );
};

export default LinkGallery;
