import React from 'react';

import * as dates from '../../../utils/dates';

import './styles.css';

const ResultsMobileContent = ({
    step,
    showToday,
    results,
    setShowExamModal,
}) => {
    const content = {
        0: 'Você sabe bem: entre o primeiro dia de sua gravidez até seu bebê vir ao mundo...',

        1: '...existe uma longa jornada.',
        2: (
            <>
                Essa jornada pode ser contada em{' '}
                <span className='highlight-text-purple-light'>meses</span>
                ...
            </>
        ),
        3: (
            <>
                Essa jornada pode ser contada em{' '}
                <span className='highlight-text-green-light'>semanas</span>
                ...
            </>
        ),
        4: (
            <>
                ...e até mesmo em{' '}
                <span className='highlight-text-blue-light'>dias</span>.
            </>
        ),
        5: {
            title: 'Este é o seu período gestacional.',
            date: '',
            body: (
                <>
                    Contar a gravidez em{' '}
                    <span className='weight-900'>dias</span> é uma nova forma de
                    enxergar a gestação e{' '}
                    <span className='weight-900'>
                        entender alguns de seus momentos mais decisivos
                    </span>
                    , tais como o primeiro dia de gestação e o período de termo.
                    Vamos saber um pouco mais sobre cada um deles a seguir!
                </>
            ),
        },
        6: {
            title: 'Primeiro dia',
            date: dates.formatDate(results['Period']),
            body: 'Por convenção, a gestação começa a ser contada a partir do primeiro dia da sua última menstruação, mas, na verdade, você ainda não estava de fato grávida neste dia! A fecundação, que acontece depois de ter relação sexual, só ocorre aproximadamente duas semanas depois.',
        },
        7: {
            title: 'Data provável da ovulação',
            date: dates.formatDate(results['Ovulation']),
            body: 'Provavelmente perto desta data você teve relações sexuais. A ovulação ocorre em torno de 14 dias ANTES da menstruação. O ciclo menstrual é o tempo entre o primeiro dia de menstruação de um ciclo e o primeiro dia do ciclo seguinte. Esse tempo varia de pessoa para pessoa, embora o mais comum seja que dure em torno de 28 dias.',
        },
        8: {
            title: '1º dia do atraso da menstruação',
            date: dates.formatDate(results['Delay']),
            body: 'Esse foi o dia em que você deveria ter ficado menstruada - mas não ficou. Ainda era muito cedo para um teste de gravidez, mas algumas pessoas já podem inclusive ter sintomas de que estão grávidas!',
        },
        9: {
            title: 'Você está aqui!',
            date: dates.formatDate(new Date()),
            body: (
                <>
                    <div>{`Hoje você está no dia ${
                        results['G. Age']
                    } da sua gestação. Isso equivale a ${dates.formatGestationalAge(
                        results['G. Age']
                    )}, no ${
                        Math.floor(results['G. Age'] / 30) + 1
                    }º mês de gestação no ${
                        Math.floor(results['G. Age'] / 90) + 1
                    }º trimestre.`}</div>
                    {results['G. Age'] <= 98 && (
                        <div
                            id='results-exam-mb-disclaimer'
                            onClick={() => setShowExamModal(true)}
                        >
                            <span className='info-square-icon'></span>
                            Quando fazer o primeiro ultrassom?
                        </div>
                    )}
                </>
            ),
        },
        10: {
            title: 'O período de termo',
            date: `${dates.formatDate(
                results['Term (Start)']
            )} a ${dates.formatDate(results['Term (End)'])}`,
            body: (
                <>
                    Vamos agora olhar com mais atenção para a parte final da
                    gestação, o{' '}
                    <span className='highlight-text-blue-dark'>
                        período de termo
                    </span>
                    . O bebê que nasce{' '}
                    <span className='highlight-text-blue-light'>antes</span> de
                    chegar no período de termo é considerado prematuro , e pode
                    necessitar de cuidados especiais.
                </>
            ),
        },
        11: {
            title: 'O período de termo',
            date: `${dates.formatDate(
                results['Term (Start)']
            )} a ${dates.formatDate(results['Term (End)'])}`,
            body: (
                <>
                    {results['Cycle'] === 28
                        ? 'O período de termo vai de 37 semanas completas (dia 259) até 41 semanas mais 6 dias de gestação (dia 293). '
                        : 'Em pessoas com o ciclo típico de 28 dias, o período de termo vai de 37 semanas completas (dia 259) até 41 semanas mais 6 dias de gestação (dia 293). Essa é a informação que você vai obter geralmente, mas aqui nós adaptamos à sua realidade. '}
                    <span className='weight-900'>
                        Nesse período de 5 semanas (mais de um mês!), é saudável
                        e esperado que o trabalho de parto comece
                        espontaneamente.
                    </span>
                </>
            ),
        },
        12: {
            title: 'Data provável do parto (DPP)',
            date: dates.formatDate(results['Birth']),
            body: (
                <>
                    A probabilidade de seu bebê nascer de forma espontânea
                    aumenta dia a dia dentro do período de termo, até atingir um
                    máximo no dia {results['Cycle'] + 252} (
                    {dates.formatGestationalAge(results['Cycle'] + 252)}).{' '}
                    {results['Cycle'] !== 28 &&
                        'Em pessoas com ciclo típico de 28 dias, a DPP corresponde ao dia 280 (40 semanas completas). '}
                    Essa é a data "provável" do parto que as calculadoras
                    mostram ou mesmo que obstetras informam.{' '}
                </>
            ),
        },
        13: 'Apesar de esse dia ser chamado de Data Provável do Parto (DPP), de cada 100 crianças,...',
        14: '...apenas 4 nascem exatamente nessa data. Praticamente a mesma quantidade de bebês vai nascer alguns dias antes ou alguns dias depois dessa data!',
        15: (
            <>
                <div>
                    Muitos bebês nascem - e bem - depois da data provável do
                    parto. Ou seja, essa data não é um limite máximo...
                </div>
                <div>
                    Na verdade, seria esperado que{' '}
                    <span className='highlight-text-blue'>40% dos bebês</span>{' '}
                    nasça depois dessa data!
                </div>
            </>
        ),
        16: (
            <>
                Muitos protocolos médicos dizem que o máximo que se pode esperar
                pelo início espontâneo do trabalho de parto é até o{' '}
                <span className='highlight-text-blue-dark'>
                    dia 293 (41 semanas mais 6 dias)
                </span>
                . Ainda assim, muitos bebês podem nascer de forma saudável
                depois disso, no período que chamamos de pós-termo.
            </>
        ),
        17: '',
    };

    return (
        <div id='results-mobile-content'>
            {step < 5 && <span>{content[step]}</span>}
            {step >= 5 &&
                ((showToday && step <= 12) || (!showToday && step <= 11)) && (
                    <div>
                        <div id='results-mb-content-title'>
                            {showToday && content[step].title}
                            {!showToday && step < 9 && content[step].title}
                            {!showToday && step >= 9 && content[step + 1].title}
                        </div>
                        <div id='results-mb-content-date'>
                            {showToday && content[step].date}
                            {!showToday && step < 9 && content[step].date}
                            {!showToday && step >= 9 && content[step + 1].date}
                        </div>
                        <div id='results-mb-content-body'>
                            {showToday && content[step].body}
                            {!showToday && step < 9 && content[step].body}
                            {!showToday && step >= 9 && content[step + 1].body}
                        </div>
                    </div>
                )}
            {((showToday && step >= 13) || (!showToday && step >= 12)) && (
                <span>{showToday ? content[step] : content[step + 1]}</span>
            )}
        </div>
    );
};

export default ResultsMobileContent;
