import React from 'react';
import { useNavigate } from 'react-router-dom';

import HeadingTwo from '../../components/Heading/Two';
import Paragraph from '../../components/Paragraph';
import NavButtons from '../../components/NavButtons';

import { BLOG } from '../../constants/routes';
import { referencesData } from '../../constants/references';

import './styles.css';

const ReferencesPage = () => {
    const navigate = useNavigate();
    const previousFunction = () => navigate(BLOG);
    const nextFunction = () => {};

    return (
        <>
            <div id='references-page'>
                <main id='references-page-content'>
                    <HeadingTwo color='green'>Referências</HeadingTwo>
                    {referencesData.map((reference, i) => (
                        <Paragraph
                            key={`reference-${i}`}
                            size='medium'
                            color='dark'
                        >
                            <a href={reference.link} target='__blank'>
                                [{i + 1}]
                            </a>
                            {'  '}
                            {reference.authors}{' '}
                            <span className='weight-800'>
                                {reference.title}
                            </span>{' '}
                            <span className='decoration-italic'>
                                {reference.source}
                            </span>{' '}
                            {reference.pages}
                        </Paragraph>
                    ))}
                </main>
            </div>
            <NavButtons
                pageName='references'
                previousFunction={previousFunction}
                nextFunction={nextFunction}
            />
        </>
    );
};

export default ReferencesPage;
