import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NavButtons from '../../components/NavButtons';
import ExtrasIntroView from '../../components/Extras/IntroView';
import ExtrasBirthsByDayView from '../../components/Extras/BirthsByDayView';
import ExtrasBirthsByTypeView from '../../components/Extras/BirthsByTypeView';
import ExtrasFindingsView from '../../components/Extras/FindingsView';
import ExtrasFinalView from '../../components/Extras/FinalView';

import { RESULTS, BLOG } from '../../constants/routes';

import './styles.css';

const ExtrasPage = () => {
    const [step, setStep] = useState(0);
    const navigate = useNavigate();

    const views = [
        <ExtrasIntroView />,
        <ExtrasBirthsByDayView step={step} />,
        <ExtrasBirthsByTypeView />,
        <ExtrasFindingsView />,
        <ExtrasFinalView />,
    ];

    const previousFunction = () =>
        step === 0 ? navigate(RESULTS) : setStep(step - 1);
    const nextFunction = () => (step < 6 ? setStep(step + 1) : navigate(BLOG));

    return (
        <div id='extras-page' className={`step-${step}`}>
            <main id='extras-page-content'>
                {step === 0 && views[step]}
                {step >= 1 && step <= 3 && views[1]}
                {step > 3 && views[step - 2]}
            </main>
            <NavButtons
                pageName='extras'
                previousFunction={previousFunction}
                nextFunction={nextFunction}
            />
        </div>
    );
};

export default ExtrasPage;
