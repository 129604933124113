import React from 'react';
import { useNavigate } from 'react-router-dom';

import HeadingTwo from '../../components/Heading/Two';
import LinkGallery from '../../components/LinkGallery';
import NavButtons from '../../components/NavButtons';

import { EXTRAS, REFERENCES } from '../../constants/routes';
import { blogPosts } from '../../constants/blog';

import './styles.css';

const BlogPage = () => {
    const navigate = useNavigate();
    const previousFunction = () => navigate(EXTRAS);
    const nextFunction = () => navigate(REFERENCES);

    return (
        <>
            <main id='blog-page'>
                <section id='blog-page-content'>
                    <HeadingTwo color='dark'>
                        Saiba mais sobre esse e outros assuntos no nosso blog:
                    </HeadingTwo>
                    <LinkGallery items={blogPosts} />
                </section>
            </main>
            <NavButtons
                pageName='blog'
                previousFunction={previousFunction}
                nextFunction={nextFunction}
            />
        </>
    );
};

export default BlogPage;
