import * as d3 from 'd3';

import * as utils from './charts';
import * as dates from './dates';

import BabyIcons from '../assets/photos/baby-icons.gif';
import MotherIcons from '../assets/photos/mother-icons.gif';

const createArrowDefinition = (svg) => {
    const definition = {
        size: 8,
        refs: 4,
        viewbox: [0, 0, 8, 8],
        line: [
            [0, 0],
            [0, 8],
            [8, 4],
        ],
    };

    svg.select('#arrow-definition').remove();

    svg.append('defs')
        .append('marker')
        .attr('id', 'arrow-definition')
        .attr('viewBox', definition.viewbox)
        .attr('refX', definition.refs)
        .attr('refY', definition.refs)
        .attr('markerWidth', definition.size)
        .attr('markerHeight', definition.size)
        .attr('orient', 'auto-start-reverse')
        .append('path')
        .attr('d', d3.line()(definition.line))
        .attr('fill', '#d3307d');
};

const addIconsLink = (svg, config) => {
    const squareSymbol = d3.symbol().type(d3.symbolSquare).size(50);

    const leftSymbolTransform = utils.rotateSquareSymbol(
        config.width / 2 - 100,
        config.height * 0.85
    );

    const rightSymbolTransform = utils.rotateSquareSymbol(
        config.width / 2 + 100,
        config.height * 0.85
    );

    const link = utils.createHorizontalLink(
        config.width / 2 - 100,
        config.height * 0.85,
        config.width / 2 + 100,
        config.height * 0.85
    );

    svg.append('path')
        .attr('id', 'results-icons-link')
        .attr('d', link)
        .attr('opacity', '100%');

    svg.append('path')
        .attr('id', 'results-link-symbol-left')
        .attr('d', squareSymbol)
        .attr('transform', leftSymbolTransform)
        .attr('opacity', '100%');

    svg.append('path')
        .attr('id', 'results-link-symbol-right')
        .attr('d', squareSymbol)
        .attr('transform', rightSymbolTransform)
        .attr('opacity', '100%');
};

const addLinkedIcons = (svg, config) => {
    svg.append('image')
        .attr('id', 'results-mother-icons')
        .attr('href', MotherIcons)
        .attr('height', 120)
        .attr('width', 98)
        .attr('x', config.width / 2 - 228)
        .attr('y', config.height * 0.85 - 90)
        .attr('opacity', '100%');

    svg.append('image')
        .attr('id', 'results-baby-icons')
        .attr('href', BabyIcons)
        .attr('height', 120)
        .attr('width', 98)
        .attr('x', config.width / 2 + 130)
        .attr('y', config.height * 0.85 - 90)
        .attr('opacity', '100%');
};

const addVerticalAxis = (svg, config) => {
    const yAxis = d3
        .axisLeft(config.yScale)
        .ticks(5)
        .tickFormat(d3.format('.0%'))
        .tickSizeOuter(0);

    svg.append('text')
        .attr('id', 'results-chart-y-axis-title')
        .attr(
            'transform',
            `translate(${config.margin.left * 0.3}, ${
                (config.height * 0.85) / 2
            }) rotate(-90)`
        )
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('opacity', '0%')
        .text('% dos nascimentos a termo');

    svg.append('g')
        .attr('id', 'results-chart-y-axis')
        .attr('transform', `translate(${config.margin.left}, 0)`)
        .attr('opacity', '0%')
        .call(yAxis);
};

const addGridlines = (svg, config) => {
    const gridlines = svg
        .append('g')
        .attr('id', 'results-chart-gridlines')
        .attr('opacity', '0%');

    gridlines
        .selectAll('.results-chart-gridline')
        .data(config.yScale.ticks(5))
        .enter()
        .append('line')
        .attr('class', 'results-chart-gridline')
        .attr('x1', config.margin.left)
        .attr('x2', config.width - config.margin.right)
        .attr('y1', (d) => config.yScale(d))
        .attr('y2', (d) => config.yScale(d));
};

const addMonthBars = (svg, config, data) => {
    const monthData = utils.getMonthData(data, 'baseWidth').reverse();
    const monthBars = svg.append('g').attr('id', 'results-month-bars');

    monthBars
        .selectAll('.results-month-bar')
        .data(monthData)
        .enter()
        .append('rect')
        .attr('id', (m) => `results-month-bar-${m.month}`)
        .attr('class', 'results-month-bar')
        .attr('x', (m) =>
            utils.getGroupBarPosition(m, config, monthData, 'month')
        )
        .attr('y', config.height * 0.85 - 8)
        .attr('height', 16)
        .attr(
            'width',
            (m) => utils.getGroupBarWidth(m, config) - config.bars.line
        )
        .attr('opacity', '0%');
};

const addWeekBars = (svg, config, data) => {
    const weekData = utils.getWeekData(data, 'baseWidth').reverse();
    const weekBars = svg.append('g').attr('id', 'results-week-bars');

    weekBars
        .selectAll('.results-week-bar')
        .data(weekData)
        .enter()
        .append('rect')
        .attr('id', (w) => `results-week-bar-${w.week}`)
        .attr('class', 'results-week-bar')
        .attr('x', (w) =>
            utils.getGroupBarPosition(w, config, weekData, 'week')
        )
        .attr('y', config.height * 0.85 - 8)
        .attr('height', 16)
        .attr(
            'width',
            (w) => utils.getGroupBarWidth(w, config) - config.bars.line
        )
        .attr('opacity', '0%');
};

const addDayBars = (svg, config, data) => {
    const dayBars = svg.append('g').attr('id', 'results-day-bars');

    dayBars
        .selectAll('.results-day-bar')
        .data(data.reverse())
        .enter()
        .append('rect')
        .attr('id', (d) => `results-day-bar-${d.day}`)
        .attr('class', 'results-day-bar')
        .attr('x', (d) =>
            utils.getDayBarPosition(d.day, data, config, 'baseWidth')
        )
        .attr('y', config.height * 0.85 - 20)
        .attr('height', 40)
        .attr('width', config.bars.line)
        .attr('opacity', '0%');
};

const addDayBarsTexts = (svg, config) => {
    svg.append('text')
        .attr('id', 'results-day-bar-text-1')
        .attr('class', 'results-day-bar-text')
        .attr('x', 0)
        .attr('y', config.height * 0.85 + 2)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text('');

    svg.append('text')
        .attr('id', 'results-day-bar-text-2')
        .attr('class', 'results-day-bar-text')
        .attr('x', 0)
        .attr('y', config.height * 0.85 + 2)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text('');
};

const addCenteredText = (svg, config) => {
    svg
        .append('text')
        .attr('id', 'results-centered-text')
        .attr('x', '50%')
        .attr('y', config.height * 0.135)
        .attr('opacity', '100%')
        .append('tspan').html(`
            <tspan x="50%">Você sabe bem: entre o primeiro dia de sua</tspan>
            <tspan x="50%" dy='1.6em'> gravidez até seu bebê vir ao mundo...</tspan>
        `);
};

const addResultsText = (svg, config) => {
    const text = svg
        .append('text')
        .attr('id', 'results-explanation-text')
        .attr('x', 0)
        .attr('y', config.height * 0.121)
        .attr('opacity', '0%');

    text.append('tspan').attr('id', 'results-explanation-text-title');
    text.append('tspan').attr('id', 'results-explanation-text-date');
    text.append('tspan').attr('id', 'results-explanation-text-body');
};

const addOverviewMarkers = (svg, config, data, results) => {
    const overviewData = [
        {
            day: 1,
            orient: 'left',
            height: 350,
            text: 'Primeiro dia da gestação',
        },
        {
            day: results['Cycle'] - 13,
            orient: 'left',
            height: 290,
            text: 'Ovulação',
        },
        {
            day: results['Cycle'] + 1,
            orient: 'left',
            height: 230,
            text: 'Atraso na menstruação',
        },
        {
            day: results['Cycle'] + 231,
            orient: 'right',
            height: 50,
            text: 'Início do período de termo',
        },
        {
            day: results['Cycle'] + 252,
            orient: 'right',
            height: 110,
            text: 'Data "provável" do parto',
        },
        {
            day: results['Cycle'] + 265,
            orient: 'right',
            height: 170,
            text: 'Fim do período de termo',
        },
    ];

    const markers = svg
        .append('g')
        .attr('id', 'results-overview-markers')
        .attr('opacity', '0%');

    overviewData.forEach((d) => {
        markers
            .append('line')
            .attr(
                'x1',
                utils.getDayBarPosition(d.day, data, config, 'baseWidth') +
                    config.bars.line / 2
            )
            .attr(
                'x2',
                utils.getDayBarPosition(d.day, data, config, 'baseWidth') +
                    config.bars.line / 2
            )
            .attr('y1', config.height * 0.85 - 20)

            .attr('y2', config.height * 0.85 - d.height);

        markers
            .append('text')
            .attr(
                'x',
                d.orient === 'left'
                    ? utils.getDayBarPosition(
                          d.day,
                          data,
                          config,
                          'baseWidth'
                      ) + 20
                    : utils.getDayBarPosition(
                          d.day,
                          data,
                          config,
                          'baseWidth'
                      ) - 20
            )
            .attr('y', config.height * 0.85 - d.height + 10)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', d.orient === 'left' ? 'start' : 'end')
            .text(d.text);
    });
};

const addDayDetailsMarkers = (svg) => {
    const marker1 = svg
        .append('g')
        .attr('id', 'day-details-marker-1')
        .attr('class', 'day-details-marker')
        .attr('opacity', '0%');

    const marker2 = svg
        .append('g')
        .attr('id', 'day-details-marker-2')
        .attr('class', 'day-details-marker')
        .attr('opacity', '0%');

    [marker1, marker2].forEach((marker, i) => {
        marker
            .append('line')
            .attr('class', 'vertical-line')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 200);

        marker
            .append('line')
            .attr('class', 'horizontal-line')
            .attr('x1', i === 0 ? 15 : -15)
            .attr('x2', i === 0 ? 325 : -325)
            .attr('y1', 55)
            .attr('y2', 55);

        marker
            .append('line')
            .attr('class', 'horizontal-line')
            .attr('x1', i === 0 ? 15 : -15)
            .attr('x2', i === 0 ? 325 : -325)
            .attr('y1', 105)
            .attr('y2', 105);

        marker
            .append('rect')
            .attr('class', 'day-details-rect-day')
            .attr('x', i === 0 ? 25 : -30)
            .attr('y', 20)
            .attr('height', 16)
            .attr('width', 5);

        marker
            .append('text')
            .attr('id', `day-details-text-day-${i + 1}`)
            .attr('class', 'day-details-text-day')
            .attr('x', i === 0 ? 50 : -50)
            .attr('y', 30)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', i === 0 ? 'start' : 'end')
            .text('');

        marker
            .append('rect')
            .attr('class', 'day-details-rect-week')
            .attr('x', i === 0 ? 25 : -40)
            .attr('y', 72)
            .attr('height', 16)
            .attr('width', 15);

        marker
            .append('text')
            .attr('id', `day-details-text-week-${i + 1}`)
            .attr('class', 'day-details-text-week')
            .attr('x', i === 0 ? 60 : -60)
            .attr('y', 82)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', i === 0 ? 'start' : 'end')
            .text('');

        marker
            .append('rect')
            .attr('class', 'day-details-rect-month')
            .attr('x', i === 0 ? 25 : -60)
            .attr('y', 122)
            .attr('height', 16)
            .attr('width', 35);

        marker
            .append('text')
            .attr('id', `day-details-text-month-${i + 1}`)
            .attr('class', 'day-details-text-month')
            .attr('x', i === 0 ? 80 : -80)
            .attr('y', 132)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', i === 0 ? 'start' : 'end')
            .text('');
    });
};

const addBirthDayMarker = (svg, config, data) => {
    const x =
        utils.getDayBarPosition(280, data, config, 'chart') +
        config.bars.large / 2;

    const marker = svg
        .append('g')
        .attr('class', 'birth-day-marker')
        .attr('opacity', '0%');

    marker
        .append('line')
        .attr('x1', x)
        .attr('x2', x)
        .attr('y1', config.height - 43)

        .attr('y2', config.height - 170)
        .attr('stroke', 'dark');

    marker
        .append('text')
        .attr('x', x)
        .attr('y', config.height - 23)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text('280 (DPP)');
};

const addExamDisclaimer = (svg, config) => {
    svg.append('rect')
        .attr('id', 'results-exam-disclaimer-wrapper')
        .attr('x', config.width * 0.7)
        .attr('y', config.height * 0.075)
        .attr('height', config.height * 0.241)
        .attr('width', config.width / 4)
        .attr('opacity', '0%');

    svg
        .append('text')
        .attr('id', 'results-exam-disclaimer-text')
        .attr('x', config.width * 0.7)
        .attr('y', config.height * 0.075)
        .attr('opacity', '0%').html(`
            <tspan x="955" dy="3em"><tspan class='weight-700'>Quando fazer o primeiro ultrassom?</tspan></tspan>
            <tspan x="955" dy="2.25em">Um ultrassom feito entre 7 e 14</tspan>
            <tspan x="955" dy="1.25em">semanas de gestação fornece a</tspan>
            <tspan x="955" dy="1.25em">estimativa mais precisa da idade</tspan>
            <tspan x="955" dy="1.25em">gestacional, pois neste período o</tspan>
            <tspan x="955" dy="1.25em">exame é mais preciso do que aqueles</tspan>
            <tspan x="955" dy="1.25em">realizados com a gravidez mais</tspan>
            <tspan x="955" dy="1.25em">avançada.</tspan>
        `);
};

const addTermPeriodMarker = (svg, config, data, column) => {
    svg.append('line')
        .attr('id', 'term-period-marker')
        .attr('class', 'horizontal-line')
        .attr(
            'x1',
            utils.getDayBarPosition(259, data, config, column) +
                config.bars.line / 2
        )
        .attr(
            'x2',
            utils.getDayBarPosition(293, data, config, column) +
                config.bars.line / 2
        )
        .attr('y1', config.height * 0.85 - 25)
        .attr('y2', config.height * 0.85 - 25)
        .attr('opacity', '0%');

    svg.append('text')
        .attr('id', 'term-period-marker-text')
        .attr(
            'x',
            utils.getDayBarPosition(276, data, config, column) +
                config.bars.line / 2
        )
        .attr('y', config.height * 0.85 - 37)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text('Período de Termo')
        .attr('opacity', '0%');
};

const addArrow = (svg, type, x1, y1, x2, y2) => {
    const link =
        type === 'horizontal'
            ? d3
                  .linkHorizontal()
                  .x((d) => d.x)
                  .y((d) => d.y)({
                  source: { x: x1, y: y1 },
                  target: { x: x2, y: y2 },
              })
            : d3
                  .linkVertical()
                  .x((d) => d.x)
                  .y((d) => d.y)({
                  source: { x: x1, y: y1 },
                  target: { x: x2, y: y2 },
              });

    const arrow = svg
        .append('path')
        .attr('class', 'results-chart-arrow')
        .attr('d', link)
        .attr('marker-end', 'url(#arrow-definition)')
        .attr('stroke', '#d3307d')
        .attr('fill', 'none');

    return arrow;
};

const addChartFloatingTexts = (svg, config) => {
    [1, 2, 3].forEach((i) => {
        svg.append('rect')
            .attr('id', `results-chart-floating-wrapper-${i}`)
            .attr('class', 'results-chart-floating-wrapper')
            .attr('opacity', '0%');

        svg.append('text')
            .attr('id', `results-chart-floating-text-${i}`)
            .attr('class', 'results-chart-floating-text')
            .attr('opacity', '0%');
    });
};

export const addBaseComponents = (svg, config, data, results) => {
    svg.selectAll('*').remove();

    createArrowDefinition(svg);

    addIconsLink(svg, config);
    addLinkedIcons(svg, config);

    addVerticalAxis(svg, config);
    addGridlines(svg, config);

    addMonthBars(svg, config, data);
    addWeekBars(svg, config, data);
    addDayBars(svg, config, data);
    addDayBarsTexts(svg, config);

    addCenteredText(svg, config);
    addOverviewMarkers(svg, config, data, results);
    addDayDetailsMarkers(svg, config, data);
    addExamDisclaimer(svg, config);
    addTermPeriodMarker(svg, config, data, 'baseWidth');
    addResultsText(svg, config);

    addBirthDayMarker(svg, config, data);
    addChartFloatingTexts(svg, config);
};

export const extendIconsLink = (svg, config) => {
    const transition = utils.createLinearTransition(250);

    const link = utils.createHorizontalLink(
        config.width / 2 - 460,
        config.height * 0.85,
        config.width / 2 + 460,
        config.height * 0.85
    );

    const leftSymbolTransform = utils.rotateSquareSymbol(
        config.width / 2 - 460,
        config.height * 0.85
    );

    const rightSymbolTransform = utils.rotateSquareSymbol(
        config.width / 2 + 460,
        config.height * 0.85
    );

    svg.select('#results-icons-link').attr('opacity', '100%');
    svg.selectAll('.results-month-bar').attr('opacity', '0%');

    svg.select('#results-icons-link').transition(transition).attr('d', link);

    svg.select('#results-link-symbol-left')
        .transition(transition)
        .attr('transform', leftSymbolTransform);

    svg.select('#results-link-symbol-right')
        .transition(transition)
        .attr('transform', rightSymbolTransform);

    svg.select('#results-mother-icons')
        .transition(transition)
        .attr('x', config.width / 2 - 588);

    svg.select('#results-baby-icons')
        .transition(transition)
        .attr('x', config.width / 2 + 490);

    svg.select('#results-centered-text').html(
        `<tspan x="50%">...existe uma longa jornada.</tspan>`
    );
};

export const showMonthBars = (svg, config) => {
    const opacityTransition = utils.createLinearTransition(250);

    svg.selectAll('.results-week-bar').attr('opacity', '0%');
    svg.selectAll('.results-month-bar').attr('y', config.height * 0.85 - 8);
    svg.select('#results-icons-link').attr('opacity', '50%');

    svg.selectAll('.results-month-bar')
        .transition(opacityTransition)
        .attr('opacity', '100%');

    svg.select('#results-centered-text').html(`
        <tspan x="50%">Essa jornada pode ser contada</tspan>
        <tspan x="50%" dy="1.6em">em <tspan class='color-purple'>meses</tspan>...</tspan>
    `);
};

export const showWeekBars = (svg, config) => {
    const opacityTransition = utils.createLinearTransition(100, 200);
    const positionTransition = utils.createLinearTransition(250);

    svg.selectAll('.results-day-bar').attr('opacity', '0%');
    svg.selectAll('.results-week-bar').attr('y', config.height * 0.85 - 8);
    svg.select('#results-icons-link').attr('opacity', '50%');

    svg.selectAll('.results-week-bar')
        .transition(opacityTransition)
        .attr('opacity', '100%');

    svg.selectAll('.results-month-bar')
        .transition(positionTransition)
        .attr('y', config.height * 0.85 + 40);

    svg.select('#results-centered-text').html(`
        <tspan x="50%">Essa jornada pode ser contada</tspan>
        <tspan x="50%" dy="1.6em">em <tspan class='color-green'>semanas</tspan>...</tspan>
    `);
};

export const showDayBars = (svg, config) => {
    const opacityTransition = utils.createLinearTransition(100, 200);
    const positionTransition = utils.createLinearTransition(250);

    svg.select('#results-explanation-text').attr('opacity', '0%');
    svg.select('#results-overview-markers').attr('opacity', '0%');

    const selectors = [
        '#results-mother-icons',
        '#results-baby-icons',
        '#results-mother-icons',
        '#results-link-symbol-left',
        '#results-link-symbol-right',
    ];

    svg.select('#results-icons-link').attr('opacity', '50%');
    selectors.forEach((s) => svg.select(s).attr('opacity', '100%'));

    svg.selectAll('.results-day-bar')
        .classed('highlighted-line-day', false)
        .transition(opacityTransition)
        .attr('opacity', '100%');

    svg.selectAll('.results-week-bar')
        .transition(positionTransition)
        .attr('y', config.height * 0.85 + 22);

    svg.select('#results-centered-text').attr('opacity', '100%');
    svg.select('#results-centered-text').html(`
        <tspan x="50%">Essa jornada pode ser contada</tspan>
        <tspan x="50%" dy="1.6em">em <tspan class='color-blue'>dias</tspan>...</tspan>
    `);
};

export const showPregnancyOverview = (svg, config, data, results) => {
    const transition = utils.createLinearTransition(250);
    const selectors = [
        '#results-mother-icons',
        '#results-baby-icons',
        '#results-mother-icons',
        '#results-icons-link',
        '#results-link-symbol-left',
        '#results-link-symbol-right',
        '#results-centered-text',
        '#day-details-marker-1',
    ];

    changeBarsWidth(svg, config, data, 'baseWidth');
    svg.select('#results-day-bar-text-1').text('');
    [
        1,
        results['Cycle'] - 13,
        results['Cycle'] + 1,
        results['Cycle'] + 231,
        results['Cycle'] + 252,
        results['Cycle'] + 265,
    ].forEach((d) =>
        svg
            .select(`#results-day-bar-${d}`)
            .classed('highlighted-line-day', true)
    );

    svg.select('#day-details-marker-1').attr('opacity', '0%');
    selectors.forEach((s) =>
        svg.select(s).transition(transition).attr('opacity', '0%')
    );

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">Este é o seu período gestacional.</tspan>
    `);

    svg.select('#results-explanation-text-body').html(`
        <tspan x="25" dy='2.25em'>Contar a gravidez em <tspan class="weight-900">dias</tspan> é uma nova forma de enxergar a gestação e</tspan>
        <tspan x="25" dy='1.5em'><tspan class="weight-900">entender alguns de seus momentos mais decisivos</tspan>, tais como o</tspan>
        <tspan x="25" dy='1.5em'>primeiro dia de gestação e o período de termo. Vamos saber um pouco</tspan>
        <tspan x="25" dy='1.5em'>mais sobre cada um deles a seguir!</tspan>
    `);

    svg.select('#results-explanation-text')
        .transition(transition)
        .attr('opacity', '100%');

    svg.select('#results-overview-markers')
        .transition(transition)
        .attr('opacity', '100%');
};

const changeBarsWidth = (svg, config, data, column) => {
    const transition = utils.createLinearTransition(250);

    const monthData = utils.getMonthData(data, column).reverse();
    const weekData = utils.getWeekData(data, column).reverse();

    svg.selectAll('.results-month-bar').classed('highlighted-month', false);
    svg.selectAll('.results-month-bar')
        .data(monthData)
        .join()
        .transition(transition)
        .attr('x', (m) =>
            utils.getGroupBarPosition(m, config, monthData, 'month')
        )
        .attr(
            'width',
            (m) => utils.getGroupBarWidth(m, config) - config.bars.line
        );

    svg.selectAll('.results-week-bar').classed('highlighted-week', false);
    svg.selectAll('.results-week-bar')
        .data(weekData)
        .join()
        .transition(transition)
        .attr('x', (w) =>
            utils.getGroupBarPosition(w, config, weekData, 'week')
        )
        .attr(
            'width',
            (w) => utils.getGroupBarWidth(w, config) - config.bars.line
        );

    svg.selectAll('.results-day-bar').classed('highlighted-line-day', false);
    svg.selectAll('.results-day-bar').classed('highlighted-large-day', false);
    svg.selectAll('.results-day-bar').classed('highlighted-wide-day', false);
    svg.selectAll('.results-day-bar')
        .data(data)
        .join()
        .transition(transition)
        .attr('x', (d) => utils.getDayBarPosition(d.day, data, config, column))
        .attr('width', (d) => config.bars[d[column]]);
};

export const explainFirstDay = (svg, config, data, results) => {
    const column = 'firstDay';
    changeBarsWidth(svg, config, data, column);

    const disappearTransition = utils.createLinearTransition(0, 200);

    svg.select('#results-day-bar-1').classed('highlighted-wide-day', true);
    svg.select('#results-week-bar-0').classed('highlighted-week', true);
    svg.select('#results-month-bar-1').classed('highlighted-month', true);

    svg.select('#results-overview-markers').attr('opacity', '0%');
    svg.select('#results-day-bar-text-1')
        .text('')
        .transition(disappearTransition)
        .attr(
            'x',
            utils.getDayBarPosition(1, data, config, column) +
                config.bars.wide / 2
        )
        .text(1);

    svg.select('#day-details-text-day-1').text('Dia 1');
    svg.select('#day-details-text-week-1').text('0 semanas e 1 dia');
    svg.select('#day-details-text-month-1').text('1º mês');
    svg.select('#day-details-marker-1').attr(
        'transform',
        `translate(${utils.getDayBarPosition(1, data, config, column)}, ${
            config.height * 0.85 - 220
        })`
    );
    svg.select('#day-details-marker-1')
        .transition(disappearTransition)
        .attr('opacity', '100%');

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">Primeiro dia</tspan>
    `);
    svg.select('#results-explanation-text-date').html(`
        <tspan x='25' dy='2.25em'>📅 ${dates.formatDate(
            results['Period']
        )}</tspan>
    `);
    svg.select('#results-explanation-text-body').html(`
        <tspan x='25' dy='3.2em'>Por convenção, a gestação começa a ser contada a partir do primeiro dia</tspan>
        <tspan x='25' dy='1.5em'>da sua última menstruação, mas, na verdade, você ainda não estava de</tspan>
        <tspan x='25' dy='1.5em'>fato grávida neste dia! A fecundação, que acontece depois de ter</tspan>
        <tspan x='25' dy='1.5em'>relação sexual, só ocorre aproximadamente duas semanas depois.</tspan>
    `);
};

export const explainOvulationDay = (svg, config, data, results) => {
    const column = 'ovulation';
    changeBarsWidth(svg, config, data, column);

    const day = results['Cycle'] - 13;
    const week = Math.floor(day / 7);
    const month = Math.floor(day / 30) + 1;

    svg.select(`#results-day-bar-${day}`).classed('highlighted-wide-day', true);
    svg.select(`#results-week-bar-${week}`).classed('highlighted-week', true);
    svg.select(`#results-month-bar-${month}`).classed(
        'highlighted-month',
        true
    );

    svg.select('#results-day-bar-text-1')
        .text('')
        .transition()
        .delay(200)
        .duration(0)
        .attr(
            'x',
            utils.getDayBarPosition(day, data, config, column) +
                config.bars.wide / 2
        )
        .text(day);

    svg.select('#day-details-text-day-1').text(`Dia ${day}`);
    svg.select('#day-details-text-week-1').text(
        dates.formatGestationalAge(day)
    );
    svg.select(`#day-details-text-month-1`).text(`${month}º mês`);
    svg.select('#day-details-marker-1').attr(
        'transform',
        `translate(${utils.getDayBarPosition(day, data, config, column)}, ${
            config.height * 0.85 - 220
        })`
    );

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">Data provável da ovulação</tspan>
    `);
    svg.select('#results-explanation-text-date').html(`
        <tspan x='25' dy='2.25em'>📅 ${dates.formatDate(
            results['Ovulation']
        )}</tspan>
    `);
    svg.select('#results-explanation-text-body').html(`
        <tspan x='25' dy='3.2em'>Provavelmente perto desta data você teve relações sexuais. A ovulação</tspan>
        <tspan x='25' dy='1.5em'>ocorre em torno de 14 dias ANTES da menstruação. O ciclo menstrual é</tspan>
        <tspan x='25' dy='1.5em'>o tempo entre o primeiro dia de  menstruação de um ciclo e o primeiro </tspan>
        <tspan x='25' dy='1.5em'>dia do ciclo seguinte. Esse tempo varia de pessoa para pessoa, embora</tspan>
        <tspan x='25' dy='1.5em'>o mais comum seja que dure em torno de 28 dias.</tspan>
    `);
};

export const explainPeriodDelayDay = (svg, config, data, results) => {
    const column = 'periodDelay';
    changeBarsWidth(svg, config, data, column);

    const day = results['Cycle'] + 1;
    const week = Math.floor(day / 7);
    const month = Math.floor(day / 30) + 1;

    svg.select(`#results-day-bar-${day}`).classed('highlighted-wide-day', true);
    svg.select(`#results-week-bar-${week}`).classed('highlighted-week', true);
    svg.select(`#results-month-bar-${month}`).classed(
        'highlighted-month',
        true
    );

    svg.select('#results-day-bar-text-1')
        .text('')
        .transition()
        .delay(200)
        .duration(0)
        .attr(
            'x',
            utils.getDayBarPosition(day, data, config, column) +
                config.bars.wide / 2
        )
        .text(day);

    svg.select('#results-exam-disclaimer-wrapper').attr('opacity', '0%');
    svg.select('#results-exam-disclaimer-text').attr('opacity', '0%');
    svg.select('#day-details-marker-2').attr('opacity', '0%');
    svg.select('#day-details-text-day-1').text(`Dia ${day}`);
    svg.select('#day-details-text-week-1').text(
        dates.formatGestationalAge(day)
    );
    svg.select(`#day-details-text-month-1`).text(`${month}º mês`);
    svg.select('#day-details-marker-1')
        .attr(
            'transform',
            `translate(${utils.getDayBarPosition(day, data, config, column)}, ${
                config.height * 0.85 - 220
            })`
        )
        .attr('opacity', '100%');

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">1º dia do atraso da menstruação</tspan>
    `);
    svg.select('#results-explanation-text-date').html(`
        <tspan x='25' dy='2.25em'>📅 ${dates.formatDate(
            results['Delay']
        )}</tspan>
    `);
    svg.select('#results-explanation-text-body').html(`
        <tspan x='25' dy='3.2em'>Esse foi o dia em que você deveria ter ficado menstruada - mas não</tspan>
        <tspan x='25' dy='1.5em'>ficou. Ainda era muito cedo para um teste de gravidez, mas algumas</tspan>
        <tspan x='25' dy='1.5em'>pessoas já podem inclusive ter sintomas de que estão grávidas!</tspan>
    `);
};

export const explainToday = (svg, config, data, results) => {
    const column = 'today';
    const marker = results['G. Age'] < 250 ? 1 : 2;
    changeBarsWidth(svg, config, data, column);

    const day = results['G. Age'];
    const week = Math.floor(day / 7);
    const month = Math.floor(day / 30) + 1;

    svg.select(`#results-day-bar-${day}`).classed('highlighted-wide-day', true);
    svg.select(`#results-week-bar-${week}`).classed('highlighted-week', true);
    svg.select(`#results-month-bar-${month}`).classed(
        'highlighted-month',
        true
    );

    svg.select('#results-day-bar-text-1')
        .text('')
        .transition()
        .delay(200)
        .duration(0)
        .attr(
            'x',
            utils.getDayBarPosition(day, data, config, column) +
                config.bars.wide / 2
        )
        .text(day);

    svg.select('#term-period-marker').attr('opacity', '0%');
    svg.select('#term-period-marker-text').attr('opacity', '0%');
    svg.select('#day-details-marker-1').attr('opacity', '0%');
    svg.select('#day-details-marker-1').attr('opacity', '0%');

    svg.select(`#day-details-text-day-${marker}`).text(`Dia ${day}`);
    svg.select(`#day-details-text-week-${marker}`).text(
        `${dates.formatGestationalAge(day)}`
    );
    svg.select(`#day-details-text-month-${marker}`).text(`${month}º mês`);
    svg.select(`#day-details-marker-${marker}`)
        .attr(
            'transform',
            `translate(${
                utils.getDayBarPosition(day, data, config, column) +
                (marker === 2 && config.bars.wide)
            }, ${config.height * 0.85 - 220})`
        )
        .attr('opacity', '100%');

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">Você está aqui!</tspan>
    `);
    svg.select('#results-explanation-text-date').html(`
        <tspan x='25' dy='2.25em'>📅 ${dates.formatDate(new Date())}</tspan>
    `);
    svg.select('#results-explanation-text-body').html(`
        <tspan x='25' dy='3.2em'>Hoje você está no dia ${day} da sua gestação. Isso equivale a</tspan>
        <tspan x='25' dy='1.5em'>${dates.formatGestationalAge(
            day
        )}, no ${month}º mês de gestação no ${
        Math.floor(month / 3) + 1
    }º trimestre.</tspan>
    `);

    if (day <= 98) {
        svg.select('#results-exam-disclaimer-wrapper').attr('opacity', '100%');
        svg.select('#results-exam-disclaimer-text').attr('opacity', '100%');
    }
};

export const explainTermPeriod = (svg, config, data, results) => {
    const column = 'termPeriod';
    changeBarsWidth(svg, config, data, column);
    Array.from({ length: 35 }, (v, k) => k + results['Cycle'] + 231).forEach(
        (d) =>
            svg
                .select(`#results-day-bar-${d}`)
                .classed('highlighted-line-day', true)
    );

    const transition = utils.createLinearTransition(250);
    svg.select('#term-period-marker')
        .transition(transition)
        .attr(
            'x1',
            utils.getDayBarPosition(
                results['Cycle'] + 231,
                data,
                config,
                column
            )
        )
        .attr(
            'x2',
            utils.getDayBarPosition(
                results['Cycle'] + 265,
                data,
                config,
                column
            ) + config.bars.line
        )
        .attr('opacity', '100%');
    svg.select('#term-period-marker-text')
        .transition(transition)
        .attr(
            'x',
            utils.getDayBarPosition(
                results['Cycle'] + 248,
                data,
                config,
                column
            )
        )
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('opacity', '100%');

    svg.select('#day-details-marker-1').attr('opacity', '0%');
    svg.select('#day-details-marker-2').attr('opacity', '0%');
    svg.select('#results-exam-disclaimer-wrapper').attr('opacity', '0%');
    svg.select('#results-exam-disclaimer-text').attr('opacity', '0%');

    svg.selectAll('.results-day-bar-text').text('');

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">O período de termo</tspan>
    `);
    svg.select('#results-explanation-text-date').html(`
        <tspan x='25' dy='2.25em'>📅 ${dates.formatDate(
            results['Term (Start)']
        )} a ${dates.formatDate(results['Term (End)'])}</tspan>
    `);
    svg.select('#results-explanation-text-body').html(`
        <tspan x='25' dy='3.2em'>Vamos agora olhar com mais atenção para a parte final da gestação, o</tspan>
        <tspan x='25' dy='1.5em'><tspan class="color-blue-dark weight-900">período de termo.</tspan> O bebê que nasce <tspan class="color-blue weight-900">antes</tspan> de chegar no período de termo</tspan>
        <tspan x='25' dy='1.5em'>é considerado prematuro, e pode necessitar de cuidados especiais.</tspan>
    `);
};

export const explainTermPeriodLimits = (svg, config, data, results) => {
    const column = 'termPeriodLimits';
    changeBarsWidth(svg, config, data, column);

    const day1 = results['Cycle'] + 231;
    const week1 = Math.floor(day1 / 7);
    const month1 = Math.floor(day1 / 30) + 1;

    const day2 = results['Cycle'] + 265;
    const week2 = Math.floor(day2 / 7);
    const month2 = Math.floor(day2 / 30) + 1;

    svg.select(`#results-day-bar-${day1}`).classed(
        'highlighted-wide-day',
        true
    );
    svg.select(`#results-day-bar-${day2}`).classed(
        'highlighted-wide-day',
        true
    );
    Array.from({ length: day2 - day1 }, (v, k) => k + day1 + 1).forEach((d) =>
        svg
            .select(`#results-day-bar-${d}`)
            .classed('highlighted-large-day', true)
    );

    Array.from({ length: week2 - week1 + 1 }, (v, k) => k + week1).forEach(
        (w) =>
            svg
                .select(`#results-week-bar-${w}`)
                .classed('highlighted-week', true)
    );

    Array.from({ length: month2 - month1 + 1 }, (v, k) => k + month1).forEach(
        (m) =>
            svg
                .select(`#results-month-bar-${m}`)
                .classed('highlighted-month', true)
    );

    svg.select('#results-day-bar-text-1')
        .text('')
        .transition()
        .delay(200)
        .duration(0)
        .attr(
            'x',
            utils.getDayBarPosition(day1, data, config, column) +
                config.bars.wide / 2
        )
        .text(day1);

    svg.select('#results-day-bar-text-2')
        .text('')
        .transition()
        .delay(200)
        .duration(0)
        .attr(
            'x',
            utils.getDayBarPosition(day2, data, config, column) +
                config.bars.wide / 2
        )
        .text(day2);

    svg.select('#day-details-text-day-1').text(`Dia ${day1}`);
    svg.select('#day-details-text-week-1').text(
        dates.formatGestationalAge(day1)
    );
    svg.select('#day-details-text-month-1').text(`${month1}º mês`);
    svg.select('#day-details-marker-1')
        .attr(
            'transform',
            `translate(${utils.getDayBarPosition(
                day1,
                data,
                config,
                column
            )}, ${config.height * 0.85 - 220})`
        )
        .attr('opacity', '100%');

    svg.select('#day-details-text-day-2').text(`Dia ${day2}`);
    svg.select('#day-details-text-week-2').text(
        dates.formatGestationalAge(day2)
    );
    svg.select('#day-details-text-month-2').text(`${month2}º mês`);
    svg.select('#day-details-marker-2')
        .attr(
            'transform',
            `translate(${
                utils.getDayBarPosition(day2, data, config, column) +
                config.bars.wide
            }, ${config.height * 0.85 - 220})`
        )
        .attr('opacity', '100%');

    const transition = utils.createLinearTransition(250);
    svg.select('#term-period-marker')
        .transition(transition)
        .attr('x1', utils.getDayBarPosition(day1, data, config, column))
        .attr(
            'x2',
            utils.getDayBarPosition(day2, data, config, column) +
                config.bars.wide
        );
    svg.select('#term-period-marker-text')
        .transition(transition)
        .attr('x', utils.getDayBarPosition(day1, data, config, column) + 15)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'start');

    svg.selectAll('.results-day-bar-text').text('');

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">O período de termo</tspan>
    `);
    svg.select('#results-explanation-text-date').html(`
        <tspan x='25' dy='2.25em'>📅 ${dates.formatDate(
            results['Term (Start)']
        )} a ${dates.formatDate(results['Term (End)'])}</tspan>
    `);
    svg.select('#results-explanation-text-body').html(
        results['Cycle'] === 28
            ? `<tspan x='25' dy='3.2em'>O período de termo vai de 37 semanas completas (dia 259) até 41</tspan>
            <tspan x='25' dy='1.5em'>semanas mais 6 dias de gestação (dia 293). <tspan class="weight-900">Nesse período de 5</tspan></tspan>
            <tspan x='25' dy='1.5em'><tspan class="weight-900">semanas (mais de um mês!), é saudável e esperado que o</tspan></tspan>
            <tspan x='25' dy='1.5em'><tspan class="weight-900">trabalho de parto comece espontaneamente.</tspan></tspan>`
            : `<tspan x='25' dy='3.2em'>Em pessoas com o ciclo típico de 28 dias, o período de termo vai de 37</tspan>
            <tspan x='25' dy='1.5em'>semanas completas (dia 259) até 41 semanas mais 6 dias de gestação</tspan>
            <tspan x='25' dy='1.5em'>(dia 293). Essa é a informação que você vai obter geralmente, mas aqui</tspan>
            <tspan x='25' dy='1.5em'>nós adaptamos à sua realidade. <tspan class="weight-900">Nesse período de 5 semanas (mais</tspan></tspan>
            <tspan x='25' dy='1.5em'><tspan class="weight-900">de um mês!), é saudável e esperado que o trabalho de parto</tspan></tspan>
            <tspan x='25' dy='1.5em'><tspan class="weight-900">comece espontaneamente.</tspan></tspan>`
    );
};

export const explainExpectedBirthday = (svg, config, data, results) => {
    const column = 'expectedBirthday';
    changeBarsWidth(svg, config, data, column);

    const day = results['Cycle'] + 252;
    const week = Math.floor(day / 7);
    const month = Math.floor(day / 30) + 1;

    const appearTransition = utils.createLinearTransition(250);
    const disappearTransition = utils.createLinearTransition(0, 200);

    svg.select(`#results-day-bar-${day}`).classed('highlighted-wide-day', true);
    svg.select(`#results-day-bar-${day - 21}`).classed(
        'highlighted-line-day',
        true
    );
    svg.select(`#results-day-bar-${day + 13}`).classed(
        'highlighted-line-day',
        true
    );
    svg.select(`#results-week-bar-${week}`).classed('highlighted-week', true);
    svg.select(`#results-month-bar-${month}`).classed(
        'highlighted-month',
        true
    );
    svg.selectAll('.results-chart-arrow').remove();

    Array.from({ length: 35 }, (v, k) => k + day - 21).forEach((d) => {
        if (d !== day && d !== day - 21 && d !== day + 13)
            svg.select(`#results-day-bar-${d}`).classed(
                'highlighted-large-day',
                true
            );
    });

    svg.select('#results-day-bar-text-1')
        .text('')
        .transition(disappearTransition)
        .attr(
            'x',
            utils.getDayBarPosition(day, data, config, column) +
                config.bars.wide / 2
        )
        .text(day);
    svg.select('#results-day-bar-text-2').text('');

    svg.select('#results-chart-floating-wrapper-1').attr('opacity', '0%');
    svg.select('#results-chart-floating-text-1').attr('opacity', '0%');
    svg.select('#day-details-marker-2').attr('opacity', '0%');

    const transition = utils.createLinearTransition(250);
    svg.select('#term-period-marker')
        .transition(transition)
        .attr('x1', utils.getDayBarPosition(day - 21, data, config, column))
        .attr(
            'x2',
            utils.getDayBarPosition(day + 13, data, config, column) +
                config.bars.large
        )
        .attr('opacity', '100%');
    svg.select('#term-period-marker-text')
        .transition(transition)
        .attr('x', utils.getDayBarPosition(day - 21, data, config, column))
        .attr('opacity', '100%');

    svg.select('#day-details-text-day-1').text(`Dia ${day}`);
    svg.select('#day-details-text-week-1').text(
        dates.formatGestationalAge(day)
    );
    svg.select('#day-details-text-month-1').text(`${month}º mês`);
    svg.select('#day-details-marker-1')
        .attr(
            'transform',
            `translate(${utils.getDayBarPosition(
                day,
                data,
                config,
                column
            )} , ${config.height * 0.85 - 220})`
        )
        .attr('opacity', '100%');

    svg.select('#results-explanation-text-title').html(`
        <tspan x="25">Data provável do parto (DPP)</tspan>
    `);
    svg.select('#results-explanation-text-date').html(`
        <tspan x='25' dy='2.25em'>📅 ${dates.formatDate(
            results['Birth']
        )}</tspan>
    `);
    svg.select('#results-explanation-text-body').html(
        results['Cycle'] === 28
            ? `<tspan x='25' dy='3.2em'>A probabilidade de seu bebê nascer de forma espontânea aumenta dia</tspan>
            <tspan x='25' dy='1.5em'>a dia dentro do período de termo, até atingir um máximo no dia 280 (40</tspan>
            <tspan x='25' dy='1.5em'>semanas completas). Essa é a data "provável" do parto que as</tspan>
            <tspan x='25' dy='1.5em'>calculadoras mostram ou mesmo que obstetras informam.</tspan>`
            : `<tspan x='25' dy='3.2em'>A probabilidade de seu bebê nascer de forma espontânea aumenta dia</tspan>
            <tspan x='25' dy='1.5em'>a dia dentro do período de termo, até atingir um máximo no dia ${day}</tspan>
            <tspan x='25' dy='1.5em'>(${dates.formatGestationalAge(
                day
            )}). Em pessoas com ciclo típico de 28 dias, a DPP</tspan>
            <tspan x='25' dy='1.5em'>corresponde ao dia 280 (40 semanas completas). Essa é a data</tspan>
            <tspan x='25' dy='1.5em'>"provável" do parto que as calculadoras mostram ou mesmo que</tspan>
            <tspan x='25' dy='1.5em'>obstetras informam.</tspan>`
    );

    svg.select('#results-explanation-text')
        .transition(appearTransition)
        .attr('opacity', '100%');
};

export const createChartBase = (svg, config, data) => {
    const column = 'chart';
    svg.select('#results-day-bar-text-1').text('');
    changeBarsWidth(svg, config, data, column);

    [259, 280, 293].forEach((d) =>
        svg
            .select(`#results-day-bar-${d}`)
            .classed('highlighted-line-day', true)
    );

    const selectors = [
        '#results-explanation-text',
        '#results-chart-y-axis',
        '#results-chart-y-axis-title',
        '#results-chart-gridlines',
        '#day-details-marker-1',
        '#term-period-marker',
        '#term-period-marker-text',
        '.birth-day-marker',
    ];
    selectors.forEach((s) => svg.select(s).attr('opacity', '0%'));

    svg.selectAll('.results-chart-arrow').remove();
    const source = utils.getDayBarPosition(280, data, config, 'chart');
    addArrow(
        svg,
        'vertical',
        source + 2 * config.bars.large,
        config.height * 0.85 - 90,
        source + config.bars.large / 2,
        config.height * 0.85 - 30
    );

    const transition = utils.createLinearTransition(250);

    svg.selectAll('.results-day-bar')
        .data(data)
        .join()
        .attr('y', config.height * 0.85 - 20)
        .attr('height', 40);

    svg.select('#results-chart-floating-wrapper-1')
        .attr(
            'x',
            utils.getDayBarPosition(282, data, config, 'chart') -
                config.bars.large / 2
        )
        .attr('y', config.height * 0.85 - 160)
        .attr('height', 100)
        .attr('width', 345)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#results-chart-floating-text-1')
        .attr(
            'x',
            utils.getDayBarPosition(282, data, config, 'chart') -
                config.bars.large / 2
        )
        .attr('y', config.height * 0.85 - 160)
        .html(
            `<tspan x="880" dy="2.4em">Apesar de esse dia ser chamado de Data</tspan>
            <tspan x="880" dy="1.5em">Provável do Parto (DPP), de cada</tspan>
            <tspan x="880" dy="1.5em">100 crianças,...</tspan>
        `
        )
        .transition(transition)
        .attr('opacity', '100%');
};

export const showHalfDistribution = (svg, config, data) => {
    svg.selectAll('.results-chart-arrow').remove();
    svg.select('#results-chart-floating-wrapper-2').attr('opacity', '0%');
    svg.select('#results-chart-floating-text-2').attr('opacity', '0%');
    svg.select('#results-chart-floating-wrapper-3').attr('opacity', '0%');
    svg.select('#results-chart-floating-text-3').attr('opacity', '0%');

    svg.selectAll(`.results-day-bar`).classed('highlighted-large-day', false);
    [259, 280, 293].forEach((d) =>
        svg
            .select(`#results-day-bar-${d}`)
            .classed('highlighted-line-day', true)
    );

    const transition = utils.createLinearTransition(250);

    svg.select('#results-chart-y-axis')
        .transition(transition)
        .attr('opacity', '100%');
    svg.select('#results-chart-y-axis-title')
        .transition(transition)
        .attr('opacity', '100%');
    svg.select('#results-chart-gridlines')
        .transition(transition)
        .attr('opacity', '100%');
    svg.select('.birth-day-marker')
        .transition(transition)
        .attr('opacity', '100%');

    svg.selectAll('.results-day-bar')
        .data(data)
        .join()
        .transition(transition)
        .attr('y', (d) =>
            d.height === -1 || d.day > 280
                ? config.height * 0.85 - 20
                : config.yScale(d.height)
        )
        .attr('height', (d) =>
            d.height === -1 || d.day > 280
                ? 40
                : config.yScale(0.005) - config.yScale(d.height) + 40
        );

    svg.select('#results-chart-floating-wrapper-1')
        .attr(
            'x',
            utils.getDayBarPosition(282, data, config, 'chart') -
                config.bars.large / 2
        )
        .attr('height', 120)
        .attr('width', 345)
        .attr('opacity', '90%')
        .transition(transition)
        .attr(
            'y',
            data
                .filter((d) => d.day === 280)
                .map((d) => config.yScale(d.height))[0]
        );

    svg.select('#results-chart-floating-text-1')
        .attr(
            'x',
            utils.getDayBarPosition(282, data, config, 'chart') -
                config.bars.large / 2
        )
        .html(
            `<tspan x="880" dy="2.5em">...apenas 4 nascem exatamente nessa data.</tspan>
            <tspan x="880" dy="1.5em">Praticamente a mesma quantidade de bebês</tspan>
            <tspan x="880" dy="1.5em">vai nascer alguns dias antes ou alguns dias</tspan>
            <tspan x="880" dy="1.5em">depois dessa data!</tspan>
        `
        )
        .attr('opacity', '100%')
        .transition(transition)
        .attr(
            'y',
            data
                .filter((d) => d.day === 280)
                .map((d) => config.yScale(d.height))[0]
        );
};

export const showDistribution = (svg, config, data) => {
    const transition = utils.createLinearTransition(250);

    svg.selectAll('.results-day-bar').classed('highlighted-large-day', false);
    svg.selectAll('.results-day-bar').classed('highlighted-line-day', false);
    svg.selectAll('.results-chart-arrow').remove();

    svg.select('#results-chart-floating-wrapper-1').attr('opacity', '0%');
    svg.select('#results-chart-floating-text-1').attr('opacity', '0%');

    svg.select(`#results-day-bar-280`).classed('highlighted-line-day', true);
    Array.from({ length: 13 }, (v, k) => k + 281).forEach((d) =>
        svg
            .select(`#results-day-bar-${d}`)
            .classed('highlighted-large-day', true)
    );

    svg.selectAll('.results-day-bar')
        .data(data)
        .join()
        .transition(transition)
        .attr('y', (d) =>
            d.height === -1
                ? config.height * 0.85 - 20
                : config.yScale(d.height)
        )
        .attr('height', (d) =>
            d.height === -1
                ? 40
                : config.yScale(0.005) - config.yScale(d.height) + 40
        );

    const source =
        utils.getDayBarPosition(280, data, config, 'chart') -
        config.bars.large / 5;

    svg.select('#results-chart-floating-wrapper-2')
        .attr('x', 100)
        .attr('y', config.height * 0.01)
        .attr('height', 100)
        .attr('width', 345)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#results-chart-floating-text-2')
        .attr('x', 100)
        .attr('y', config.height * 0.01)
        .html(
            `<tspan x="275" dy="2.4em">Muitos bebês nascem - e bem - depois da</tspan>
            <tspan x="275" dy="1.5em">data provável do parto. Ou seja, essa data</tspan>
            <tspan x="275" dy="1.5em">não é um limite máximo...</tspan>`
        )
        .transition(transition)
        .attr('opacity', '100%');

    svg.select('#results-chart-floating-wrapper-3')
        .attr('x', 700)
        .attr('y', config.height * 0.425)
        .attr('height', 80)
        .attr('width', 345)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#results-chart-floating-text-3')
        .attr('x', 700)
        .attr('y', config.height * 0.425)
        .html(
            `<tspan x="875" dy="2.4em">Na verdade, seria esperado que 40% dos</tspan>
            <tspan x="875" dy="1.5em">bebês nasça <tspan class='color-blue'>depois dessa data</span>!</tspan>`
        )
        .transition(transition)
        .attr('opacity', '100%');

    addArrow(
        svg,
        'horizontal',
        415,
        config.height * 0.075,
        source,
        config.height * 0.25
    );
};

export const explainTermEnd = (svg, config, data) => {
    svg.selectAll('.results-day-bar').classed('highlighted-large-day', false);
    svg.selectAll('.results-day-bar').classed('highlighted-line-day', false);

    svg.selectAll('.results-chart-arrow').remove();
    svg.select('#results-chart-floating-wrapper-2').attr('opacity', '0%');
    svg.select('#results-chart-floating-text-2').attr('opacity', '0%');
    svg.select('#results-chart-floating-wrapper-3').attr('opacity', '0%');
    svg.select('#results-chart-floating-text-3').attr('opacity', '0%');

    Array.from({ length: 8 }, (v, k) => k + 293).forEach((d) =>
        svg
            .select(`#results-day-bar-${d}`)
            .classed('highlighted-line-day', true)
    );

    const transition = utils.createLinearTransition(250);

    svg.select('#results-chart-floating-wrapper-1')
        .attr('x', 500)
        .attr('y', config.height * 0.4)
        .attr('height', 185)
        .attr('width', 345)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#results-chart-floating-text-1')
        .attr('x', 500)
        .attr('y', config.height * 0.4)
        .html(
            `<tspan x="675" dy="2.4em">Muitos protocolos médicos dizem que o</tspan>
            <tspan x="675" dy="1.5em">máximo que se pode esperar pelo início</tspan>
            <tspan x="675" dy="1.5em">espontâneo do trabalho de parto é até o <tspan class='color-blue-dark'>dia</tspan></tspan>
            <tspan x="675" dy="1.5em"><tspan class='color-blue-dark'>293 (41 semanas mais 6 dias)</tspan>. Ainda assim,</tspan>
            <tspan x="675" dy="1.5em">muitos bebês  podem nascer de forma</tspan>
            <tspan x="675" dy="1.5em">saudável depois disso, no período que</tspan>
            <tspan x="675" dy="1.5em">chamamos de pós-termo.</tspan>`
        )
        .transition(transition)
        .attr('opacity', '90%');

    const source = utils.getDayBarPosition(293, data, config, 'chart');
    addArrow(
        svg,
        'vertical',
        815,
        config.height * 0.55,
        source + config.bars.large / 2,
        config.height * 0.685
    );
};

export const showFollowUpQuestion = (svg) => {
    svg.selectAll('.results-chart-arrow').remove();
    svg.select('#results-chart-floating-text-1').attr('opacity', '0%');
};
