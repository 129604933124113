import React, { useContext } from 'react';

import CalculatorContext from '../../../../contexts/CalculatorContext';

import HeadingFour from '../../../Heading/Four';
import Paragraph from '../../../Paragraph';

import './styles.css';

const CalculatorFormText = () => {
    const { method } = useContext(CalculatorContext);
    return (
        <section id='calculator-form-text'>
            {method === 'Last Period Date' ? (
                <HeadingFour color='blue-dark'>
                    Data da Última Menstruação
                </HeadingFour>
            ) : (
                <HeadingFour color='purple-dark'>
                    Data do Primeiro Ultrassom
                </HeadingFour>
            )}
            {method === 'Last Period Date' ? (
                <Paragraph color='dark'>
                    Considere como o primeiro dia da sua última menstruação
                    aquele em que surgiu um pequeno sangramento, mesmo que
                    cor-de-rosa ou em pequena quantidade.
                </Paragraph>
            ) : (
                <Paragraph color='dark'>
                    Um ultrassom feito entre 7 e 14 semanas de gestação fornece
                    a estimativa mais precisa da idade gestacional (IG). Para IG
                    no primeiro ultrassom maior do que 14 semanas, a estimativa
                    não é tão exata (e fica menos precisa conforme a gravidez
                    avança).{' '}
                    <span className='weight-900'>
                        Nesse caso, sugerimos que você consulte também o cálculo
                        a partir da data da última menstruação.
                    </span>
                </Paragraph>
            )}
        </section>
    );
};

export default CalculatorFormText;
