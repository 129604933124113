export const changeDateByHours = (date, forward = 0, backward = 0) => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + forward - backward);

    return newDate;
};

export const changeDateByDays = (date, forward = 0, backward = 0) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + forward - backward);

    return newDate;
};

export const getDaysBetweenDates = (date1, date2) => {
    const milliseconds = date1.getTime() - date2.getTime();

    return Math.ceil(milliseconds / (1000 * 3600 * 24));
};

export const dateToString = (d) => {
    return d.toISOString().split('T')[0];
};

export const formatDate = (date) => {
    const months = [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
    ];

    return date
        ? `${date.getDate()} de ${
              months[date.getMonth()]
          } de ${date.getFullYear()}`
        : '';
};

export const formatGestationalAge = (days) => {
    const weekNumber = Math.floor(days / 7);
    const dayNumber = days % 7;

    if (dayNumber === 0)
        return `${weekNumber} semana${weekNumber === 1 ? '' : 's'} completa${
            weekNumber === 1 ? '' : 's'
        }`;

    return `${weekNumber} semana${weekNumber === 1 ? '' : 's'} e ${
        days % 7
    } dia${dayNumber === 1 ? '' : 's'}`;
};
