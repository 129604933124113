import * as d3 from 'd3';

import * as utils from './charts';

const createArrowDefinition = (svg) => {
    const definition = {
        size: 8,
        refs: 4,
        viewbox: [0, 0, 8, 8],
        line: [
            [0, 0],
            [0, 8],
            [8, 4],
        ],
    };

    svg.select('#arrow-definition').remove();

    svg.append('defs')
        .append('marker')
        .attr('id', 'arrow-definition')
        .attr('viewBox', definition.viewbox)
        .attr('refX', definition.refs)
        .attr('refY', definition.refs)
        .attr('markerWidth', definition.size)
        .attr('markerHeight', definition.size)
        .attr('orient', 'auto-start-reverse')
        .append('path')
        .attr('d', d3.line()(definition.line))
        .attr('fill', '#7077a0');
};

const addVerticalAxis = (svg, config) => {
    const yAxis = d3
        .axisLeft(config.yScale)
        .ticks(5)
        .tickFormat(d3.format('.0%'))
        .tickSizeOuter(0);

    svg.append('text')
        .attr('id', 'births-by-day-chart-y-axis-title')
        .attr(
            'transform',
            `translate(${config.margin.left * 0.3}, ${
                (config.height * 0.85) / 2
            }) rotate(-90)`
        )
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text('% dos nascimentos a termo');

    svg.append('g')
        .attr('id', 'births-by-day-chart-y-axis')
        .attr('transform', `translate(${config.margin.left}, 0)`)
        .call(yAxis);
};

const addGridlines = (svg, config) => {
    const gridlines = svg
        .append('g')
        .attr('id', 'births-by-day-chart-gridlines');

    gridlines
        .selectAll('.births-by-day-chart-gridline')
        .data(config.yScale.ticks(5))
        .enter()
        .append('line')
        .attr('class', 'births-by-day-chart-gridline')
        .attr('x1', config.margin.left)
        .attr('x2', config.width - config.margin.right)
        .attr('y1', (d) => config.yScale(d))
        .attr('y2', (d) => config.yScale(d));
};

const addMonthBars = (svg, config, data) => {
    const monthData = utils.getMonthData(data, 'width').reverse();
    const monthBars = svg.append('g').attr('id', 'births-by-day-month-bars');

    monthBars
        .selectAll('.births-by-day-month-bar')
        .data(monthData)
        .enter()
        .append('rect')
        .attr('id', (m) => `births-by-day-month-bar-${m.month}`)
        .attr('class', 'births-by-day-month-bar')
        .attr('x', (m) =>
            utils.getGroupBarPosition(m, config, monthData, 'month')
        )
        .attr('y', config.height * 0.85 + 40)
        .attr('height', 16)
        .attr(
            'width',
            (m) => utils.getGroupBarWidth(m, config) - config.bars.line
        );
};

const addWeekBars = (svg, config, data) => {
    const weekData = utils.getWeekData(data, 'width').reverse();
    const weekBars = svg.append('g').attr('id', 'births-by-day-week-bars');

    weekBars
        .selectAll('.births-by-day-week-bar')
        .data(weekData)
        .enter()
        .append('rect')
        .attr('id', (w) => `births-by-day-week-bar-${w.week}`)
        .attr('class', 'births-by-day-week-bar')
        .attr('x', (w) =>
            utils.getGroupBarPosition(w, config, weekData, 'week')
        )
        .attr('y', config.height * 0.85 + 22)
        .attr('height', 16)
        .attr(
            'width',
            (w) => utils.getGroupBarWidth(w, config) - config.bars.line
        );
};

const addDayBars = (svg, config, data) => {
    const ghostBars = svg.append('g').attr('id', 'births-by-day-ghost-bars');
    const dayBars = svg.append('g').attr('id', 'births-by-day-day-bars');
    const reversedData = data.map((d) => d).reverse();

    ghostBars
        .selectAll('.births-by-day-ghost-bar')
        .data(reversedData)
        .enter()
        .append('rect')
        .attr('id', (d) => `births-by-day-ghost-bar-${d.day}`)
        .attr('class', 'births-by-day-ghost-bar')
        .attr('x', (d) => utils.getDayBarPosition(d.day, data, config, 'width'))
        .attr('y', (d) =>
            d.expected === -1
                ? config.height * 0.85 - 20
                : config.yScale(d.expected)
        )
        .attr('height', (d) =>
            d.expected === -1
                ? 40
                : config.yScale(0.005) - config.yScale(d.expected) + 40
        )
        .attr('width', (d) => config.bars[d.width])
        .attr('opacity', '20%');

    dayBars
        .selectAll('.births-by-day-day-bar')
        .data(reversedData)
        .enter()
        .append('rect')
        .attr('id', (d) => `births-by-day-day-bar-${d.day}`)
        .attr('class', 'births-by-day-day-bar')
        .attr('x', (d) => utils.getDayBarPosition(d.day, data, config, 'width'))
        .attr('y', (d) =>
            d.expected === -1
                ? config.height * 0.85 - 20
                : config.yScale(d.expected)
        )
        .attr('height', (d) =>
            d.expected === -1
                ? 40
                : config.yScale(0.005) - config.yScale(d.expected) + 40
        )
        .attr('width', (d) => config.bars[d.width]);

    Array.from({ length: 35 }, (v, k) => k + 259).forEach((d) => {
        d !== 280 &&
            svg
                .select(`#births-by-day-day-bar-${d}`)
                .classed('highlighted-large-day', true);
    });

    dayBars
        .select('#births-by-day-day-bar-280')
        .classed('highlighted-line-day', true);
};

const addBirthDayMarker = (svg, config, data) => {
    const x =
        utils.getDayBarPosition(280, data, config, 'width') +
        config.bars.large / 2;

    const marker = svg
        .append('g')
        .attr('class', 'birth-day-marker')
        .attr('opacity', '100%');

    marker
        .append('line')
        .attr('x1', x)
        .attr('x2', x)
        .attr('y1', config.height - 43)
        .attr('y2', config.height - 170);

    marker
        .append('text')
        .attr('x', x)
        .attr('y', config.height - 23)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text('Data Provável do Parto (280 dias / 40 semanas)');
};

const addColorLegend = (svg) => {
    svg
        .append('text')
        .attr('id', 'births-by-day-chart-mb-legend-labels')
        .attr('x', 1050)
        .attr('y', 75).html(`
            <tspan><tspan id="births-by-day-chart-mb-legend-day">dias</tspan>
            <tspan id="births-by-day-chart-mb-legend-week">semanas</tspan>
            <tspan id="births-by-day-chart-mb-legend-month">meses</tspan></tspan>
        `);

    svg.append('rect')
        .attr('id', 'births-by-day-chart-legend-day')
        .attr('class', 'births-by-day-chart-legend')
        .attr('y', 600)
        .attr('width', 40);

    svg.append('text')
        .attr('class', 'births-by-day-chart-legend-label')
        .attr('x', 1050)
        .attr('y', 609)
        .html('dias');

    svg.append('rect')
        .attr('id', 'births-by-day-chart-legend-week')
        .attr('class', 'births-by-day-chart-legend')
        .attr('y', 618)
        .attr('width', 60);

    svg.append('text')
        .attr('class', 'births-by-day-chart-legend-label')
        .attr('x', 1060)
        .attr('y', 627)
        .html('semanas');

    svg.append('rect')
        .attr('id', 'births-by-day-chart-legend-month')
        .attr('class', 'births-by-day-chart-legend')
        .attr('y', 636)
        .attr('width', 50);

    svg.append('text')
        .attr('class', 'births-by-day-chart-legend-label')
        .attr('x', 1055)
        .attr('y', 645)
        .html('meses');

    svg.selectAll('.births-by-day-chart-legend')
        .attr('x', 1030)
        .attr('height', 16)
        .attr('rx', '0.2em')
        .attr('ry', '0.2em');

    svg.selectAll('.births-by-day-chart-legend-label')
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle');
};

const addChartFloatingTexts = (svg) => {
    [1, 2, 3, 4].forEach((i) => {
        svg.append('rect')
            .attr('id', `births-by-day-chart-floating-wrapper-${i}`)
            .attr('class', 'births-by-day-chart-floating-wrapper')
            .attr('opacity', '100%');

        svg.append('text')
            .attr('id', `births-by-day-chart-floating-text-${i}`)
            .attr('class', 'births-by-day-chart-floating-text')
            .attr('opacity', '100%');
    });

    svg.select('#births-by-day-chart-floating-wrapper-1')
        .attr('x', 85)
        .attr('y', 35)
        .attr('height', 80)
        .attr('width', 310);

    svg.select('#births-by-day-chart-floating-wrapper-2')
        .attr('x', 115)
        .attr('y', 490)
        .attr('height', 50)
        .attr('width', 210);

    svg.select('#births-by-day-chart-floating-wrapper-3')
        .attr('x', 559)
        .attr('y', 193)
        .attr('height', 50)
        .attr('width', 210);

    svg.select('#births-by-day-chart-floating-wrapper-4')
        .attr('x', 708)
        .attr('y', 438)
        .attr('height', 50)
        .attr('width', 210);

    svg.select('#births-by-day-chart-floating-text-1')
        .attr('y', 35)
        .html(
            `<tspan x="242" dy="2.4em">O nascimento dos bebês no <tspan class="color-blue weight-900">período</tspan></tspan>
            <tspan x="242" dy="1.5em"><tspan class="color-blue weight-900">de termo</tspan> <tspan class="weight-900">deveria</tspan> se distribuir assim...</tspan>`
        );

    svg.select('#births-by-day-chart-floating-text-2')
        .attr('y', 490)
        .html(`<tspan x="220" dy="2.05em">bem poucos aqui...</tspan>`);

    svg.select('#births-by-day-chart-floating-text-3')
        .attr('y', 193)
        .html(
            `<tspan x="665" dy="2.05em">...<tspan class="weight-900">muitos</tspan> aqui...</tspan>`
        );

    svg.select('#births-by-day-chart-floating-text-4')
        .attr('y', 438)
        .html(`<tspan x="815" dy="2.05em">...e alguns aqui.</tspan>`);
};

export const addBaseComponents = (svg, config, data) => {
    svg.selectAll('*').remove();

    createArrowDefinition(svg);

    addVerticalAxis(svg, config);
    addGridlines(svg, config);

    addMonthBars(svg, config, data);
    addWeekBars(svg, config, data);
    addDayBars(svg, config, data);

    addBirthDayMarker(svg, config, data);
    addColorLegend(svg);

    addChartFloatingTexts(svg);
};

export const showRealDistribution = (svg, config) => {
    svg.selectAll('.births-by-day-chart-floating-wrapper').attr(
        'opacity',
        '0%'
    );
    svg.selectAll('.births-by-day-chart-floating-text').attr('opacity', '0%');
    svg.selectAll(`.births-by-day-day-bar`).classed(
        'highlighted-day-lime',
        false
    );
    svg.selectAll('.births-by-day-day-bar').classed(
        'highlighted-large-day',
        false
    );

    const transition = utils.createLinearTransition(250);

    svg.selectAll('.births-by-day-day-bar')
        .transition(transition)
        .attr('y', (d) =>
            d.value === -1 ? config.height * 0.85 - 20 : config.yScale(d.value)
        )
        .attr('height', (d) =>
            d.value === -1
                ? 40
                : config.yScale(0.005) - config.yScale(d.value) + 40
        );

    svg.select('#births-by-day-chart-floating-wrapper-1')
        .attr('x', 85)
        .attr('y', 35)
        .attr('height', 102)
        .attr('width', 310)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#births-by-day-chart-floating-wrapper-2')
        .attr('x', 420)
        .attr('y', 455)
        .attr('height', 92)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#births-by-day-chart-floating-wrapper-3')
        .attr('x', 726)
        .attr('y', 455)
        .attr('height', 92)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#births-by-day-chart-floating-text-1')
        .attr('y', 35)
        .html(
            `<tspan x="242" dy="2.4em">Quando olhamos todos os partos no</tspan>
            <tspan x="242" dy="1.5em">Brasil, espontâneos ou não, eles se</tspan>
            <tspan x="242" dy="1.5em">distribuem de um jeito bem diferente:</tspan>`
        )
        .transition(transition)
        .attr('opacity', '100%');

    svg.select('#births-by-day-chart-floating-text-2')
        .attr('y', 455)
        .html(
            `<tspan x="527" dy="2.1em">Há muitos nascimentos</tspan>
            <tspan x="527" dy="1.5em">antes da data provável do</tspan>
            <tspan x="527" dy="1.5em">parto (DPP)...</tspan>`
        )
        .transition(transition)
        .attr('opacity', '100%');

    svg.select('#births-by-day-chart-floating-text-3')
        .attr('y', 455)
        .html(
            `<tspan x="832" dy="2.1em">... e apenas 31% depois,</tspan>
            <tspan x="832" dy="1.5em">quando deveriam ser</tspan>
            <tspan x="832" dy="1.5em">40%.</tspan>`
        )
        .transition(transition)
        .attr('opacity', '100%');
};

export const highlightHalfTermPeriod = (svg, config) => {
    svg.selectAll('.births-by-day-chart-floating-wrapper').attr(
        'opacity',
        '0%'
    );
    svg.selectAll('.births-by-day-chart-floating-text').attr('opacity', '0%');
    svg.selectAll('.births-by-day-day-bar').classed(
        'highlighted-large-day',
        false
    );

    const transition = utils.createLinearTransition(250);

    Array.from({ length: 21 }, (v, k) => k + 259).forEach((d) =>
        svg
            .select(`#births-by-day-day-bar-${d}`)
            .classed('highlighted-day-lime', true)
    );

    svg.selectAll('.births-by-day-day-bar')
        .attr('y', (d) =>
            d.value === -1 ? config.height * 0.85 - 20 : config.yScale(d.value)
        )
        .attr('height', (d) =>
            d.value === -1
                ? 40
                : config.yScale(0.005) - config.yScale(d.value) + 40
        );

    svg.select('#births-by-day-chart-floating-wrapper-1')
        .attr('x', 115)
        .attr('y', 245)
        .attr('height', 122)
        .attr('width', 310)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#births-by-day-chart-floating-wrapper-2')
        .attr('x', 806)
        .attr('y', 245)
        .attr('height', 70)
        .transition(transition)
        .attr('opacity', '90%');

    svg.select('#births-by-day-chart-floating-text-1')
        .attr('y', 245)
        .html(
            `<tspan x="270" dy="2.4em">Isso acontece pela prática frequente</tspan>
            <tspan x="270" dy="1.5em">no Brasil de <tspan class="weight-900"> intervir para antecipar o</tspan></tspan>
            <tspan x="270" dy="1.5em"><tspan class="weight-900">nascimento</tspan>, seja através de <tspan class="weight-900">cesárea</tspan></tspan>
            <tspan x="270" dy="1.5em"><tspan class="weight-900">agendada</tspan>...</tspan></tspan>`
        )
        .transition(transition)
        .attr('opacity', '100%');

    svg.select('#births-by-day-chart-floating-text-2')
        .attr('y', 245)
        .html(
            `<tspan x="912" dy="2.1em">...ou de <tspan class="weight-900">indução</tspan> logo que</tspan>
            <tspan x="912" dy="1.5em">a gestação atinge a DPP.</tspan>`
        )
        .transition(transition)
        .attr('opacity', '100%');
};
