import React from 'react';

import IconButton from '../Button/Icon';

import './styles.css';

const NavButtons = ({ pageName, previousFunction, nextFunction }) => {
    return (
        <>
            <div id={`${pageName}-page-mb-buttons`} className='nav-mb-buttons'>
                <IconButton
                    border='sand'
                    fill='white-dark'
                    icon='brown-left-arrow'
                    position='left'
                    text='VOLTAR'
                    onClick={previousFunction}
                />
                <IconButton
                    border='sand'
                    fill='white-dark'
                    icon='brown-right-arrow'
                    position='right'
                    text='AVANÇAR'
                    onClick={nextFunction}
                />
            </div>
            <div id={`${pageName}-page-buttons`} className='nav-buttons'>
                <IconButton
                    border='blue-dark'
                    fill='white'
                    icon='pink-left-arrow'
                    position='center'
                    onClick={previousFunction}
                />
                <IconButton
                    border='blue-dark'
                    fill='white'
                    icon='pink-right-arrow'
                    position='center'
                    onClick={nextFunction}
                />
            </div>
        </>
    );
};

export default NavButtons;
