import React from 'react';

import './styles.css';

const HeadingOne = ({ color, children }) => {
    const colorClass = color ? `color-${color}` : '';

    return <h1 className={colorClass}>{children || ''}</h1>;
};

export default HeadingOne;
