import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Paragraph from '../../Paragraph';
import RegularButton from '../../../components/Button/Regular';

import CalculatorContext from '../../../contexts/CalculatorContext';

import PregnantWomanImage from '../../../assets/photos/pregnancy-ending.png';
import { CALCULATOR } from '../../../constants/routes';
import { formatDate } from '../../../utils/dates';

import './styles.css';

const ExtrasFinalView = () => {
    const { results } = useContext(CalculatorContext);
    const navigate = useNavigate();

    return (
        <div id='extras-final-view'>
            <section id='extras-final-content'>
                <Paragraph color='white-light' size='big'>
                    {results['Birth'] && 'Você sabe agora que, apesar de '}
                    {results['Birth'] && (
                        <span className='weight-900'>{`${formatDate(
                            results['Birth']
                        )}`}</span>
                    )}
                    {results['Birth'] &&
                        ' ser a data mais provável que o seu bebê nasça, ela não é uma data exata para o nascimento e muito menos um limite: o trabalho de parto pode se iniciar espontaneamente e de maneira saudável e segura em qualquer dia dentro do período de termo.'}
                    {!results['Birth'] &&
                        'Você sabe agora que a data provável do parto não é uma data exata para o nascimento e muito menos um limite: o trabalho de parto pode se iniciar espontaneamente e de maneira saudável e segura em qualquer dia dentro do período de termo.'}
                </Paragraph>
                <Paragraph color='white-light' size='big'>
                    Respeitar a maturidade do bebê significa{' '}
                    <span className='weight-900'>
                        esperar o trabalho de parto começar espontaneamente
                    </span>
                    , sem intervenções, sempre que possível.{' '}
                    <span className='weight-900'>
                        Isso aumenta as chances de um nascimento mais seguro e
                        de uma vida mais saudável para você e para o seu bebê
                    </span>
                    .
                </Paragraph>
                {!results['Birth'] && (
                    <RegularButton
                        fill='lime'
                        text='acessar a calculadora do nascimento'
                        onClick={() => navigate(CALCULATOR)}
                    />
                )}
            </section>
            <aside
                id='extras-final-image'
                className={results['Birth'] ? '' : 'without-results'}
            >
                <img src={PregnantWomanImage} alt='' />
            </aside>
        </div>
    );
};

export default ExtrasFinalView;
