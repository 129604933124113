import React, { useContext } from 'react';

import CalculatorContext from '../../../contexts/CalculatorContext';

import CalculatorFormInputs from './Inputs';
import CalculatorFormHint from './Hint';
import CalculatorFormText from './Text';
import RegularButton from '../../Button/Regular';

import './styles.css';

const CalculatorFormView = () => {
    const { method, showDetails, entries, setStep, setMethod } =
        useContext(CalculatorContext);

    const methodClass =
        method === 'Last Period Date' ? 'method-lpd' : 'method-fed';

    return (
        <div id='calculator-form-view' className={methodClass}>
            <div id='calculator-form-content'>
                <main id='calculator-form-container'>
                    <CalculatorFormText />
                    <CalculatorFormInputs />
                </main>
                {showDetails && <CalculatorFormHint />}
                <RegularButton
                    fill={
                        (method === 'Last Period Date' && entries['Period']) ||
                        (method === 'First Exam Date' && entries['First Exam'])
                            ? 'lime'
                            : 'disable'
                    }
                    text='calcular o resultado'
                    onClick={
                        (method === 'Last Period Date' && entries['Period']) ||
                        (method === 'First Exam Date' && entries['First Exam'])
                            ? () => setStep('results')
                            : () => {}
                    }
                />
                <RegularButton
                    fill='none'
                    text='ou trocar o método de cálculo'
                    onClick={() => {
                        method === 'Last Period Date'
                            ? setMethod('First Exam Date')
                            : setMethod('Last Period Date');
                    }}
                />
            </div>
        </div>
    );
};

export default CalculatorFormView;
