export const referencesData = [
    {
        authors: 'Sengupta, Shaon, et al.',
        title: 'Adverse neonatal outcomes associated with early-term birth.',
        source: 'JAMA pediatrics',
        pages: '167.11 (2013): 1053-1059.',
        link: 'https://jamanetwork.com/journals/jamapediatrics/article-abstract/1746118',
    },
    {
        authors: 'Valencia, Catalina M., et al.',
        title: 'FIGO good practice recommendations on modifiable causes of iatrogenic preterm birth.',
        source: 'International Journal of Gynecology & Obstetrics',
        pages: '155.1 (2021): 8-12.',
        link: 'https://obgyn.onlinelibrary.wiley.com/doi/full/10.1002/ijgo.13857',
    },
    {
        authors: 'do Carmo Leal, Maria, et al.',
        title: 'Burden of early-term birth on adverse infant outcomes: a population-based cohort study in Brazil.',
        source: 'BMJ open',
        pages: '7.12 (2017): e017789.',
        link: 'https://bmjopen.bmj.com/content/7/12/e017789',
    },
    {
        authors: 'Sandall, Jane, et al.',
        title: 'Short-term and long-term effects of caesarean section on the health of women and children.',
        source: 'The Lancet',
        pages: '392.10155 (2018): 1349-1357.',
        link: 'https://www.sciencedirect.com/science/article/abs/pii/S0140673618319305',
    },
    {
        authors: 'Nielsen, Trine M., et al.',
        title: 'Long‐term cognition and behavior in children born at early term gestation: A systematic review.',
        source: 'Acta obstetricia et gynecologica Scandinavica',
        pages: '98.10 (2019): 1227-1234.',
        link: 'https://obgyn.onlinelibrary.wiley.com/doi/full/10.1111/aogs.13644',
    },
    {
        authors: 'Tefera, Maleda, et al. ',
        title: 'Elective cesarean section on term pregnancies has a high risk for neonatal respiratory morbidity in developed countries: a systematic review and meta-analysis.',
        source: 'Frontiers in pediatrics',
        pages: '8 (2020): 286.',
        link: 'https://www.frontiersin.org/articles/10.3389/fped.2020.00286/full',
    },
    {
        authors: 'Sobhy, Soha, et al.',
        title: 'Maternal and perinatal mortality and complications associated with caesarean section in low-income and middle-income countries: a systematic review and meta-analysis.',
        source: 'The Lancet',
        pages: '393.10184 (2019): 1973-1982.',
        link: 'https://www.sciencedirect.com/science/article/abs/pii/S0140673618323869',
    },
    {
        authors: 'Sandall, Jane, et al.',
        title: 'Short-term and long-term effects of caesarean section on the health of women and children.',
        source: 'The Lancet',
        pages: '392.10155 (2018): 1349-1357.',
        link: 'https://www.sciencedirect.com/science/article/abs/pii/S0140673618319305',
    },
    {
        authors:
            'Reis Queiroz, M., Ramos Junqueira, M. E., Roman Lay, A. A., de Aquino Bonilha, E., Furtado Borba, M., Castex Aly, C. M., Aparecido Moreira, R., Grilo Diniz, C. S.',
        title: 'Neonatal mortality by gestational age in days in infants born at term: A cohort study in Sao Paulo city, Brazil.',
        source: 'Plos one',
        pages: '17(11) (2022): e0277833.',
        link: 'https://journals.plos.org/plosone/article/comments?id=10.1371/journal.pone.0277833',
    },
];
