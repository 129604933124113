import BlogImage1 from '../assets/blog/1.png';
import BlogImage2 from '../assets/blog/2.png';
import BlogImage3 from '../assets/blog/3.png';

export const blogPosts = [
    {
        key: 'blog-1',
        image: BlogImage1,
        text: 'Parto normal, parto vaginal, parto natural e parto humanizado. Qual a diferença entre eles?',
        link: 'https://partodosdados.fsp.usp.br/tipos-de-nascimento/',
    },
    {
        key: 'blog-2',
        image: BlogImage2,
        text: 'Intervenções na assistência ao parto e nascimento',
        link: 'https://partodosdados.fsp.usp.br/intervencoes-na-assistencia-ao-parto-e-nascimento/',
    },
    {
        key: 'blog-3',
        image: BlogImage3,
        text: 'Benefícios do parto vaginal',
        link: 'https://partodosdados.fsp.usp.br/beneficio-parto/',
    },
];
