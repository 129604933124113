import React from 'react';

import './styles.css';

const ResultsExamModal = ({ setShowExamModal }) => {
    return (
        <div
            id='results-exam-modal-wrapper'
            onClick={() => setShowExamModal(false)}
        >
            <aside id='results-exam-modal'>
                <div id='results-exam-modal-title'>
                    Quando fazer o primeiro ultrassom?
                </div>
                <div>
                    Um ultrassom feito entre 7 e 14 semanas de gestação fornece
                    a estimativa mais precisa da idade gestacional, pois neste
                    período o exame é mais preciso do que aqueles realizados com
                    a gravidez mais avançada.
                </div>
            </aside>
        </div>
    );
};

export default ResultsExamModal;
