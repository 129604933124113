export const HOME = '/';

export const CALCULATOR = '/calculadora';

export const RESULTS = '/entenda-o-resultado';

export const EXTRAS = '/saiba-mais';

export const BLOG = '/blog';

export const REFERENCES = '/referencias';
