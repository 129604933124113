import React from 'react';

import './styles.css';

const Paragraph = ({ align, color, decoration, size, weight, children }) => {
    const alignClass = align ? `align-${align}` : '';
    const colorClass = color ? `color-${color}` : '';
    const decorationClass = decoration ? `decoration-${decoration}` : '';
    const sizeClass = size ? `p-size-${size}` : '';
    const weightClass = weight ? `weight-${weight}` : '';

    return (
        <p
            className={`${alignClass} ${colorClass} ${decorationClass} ${sizeClass} ${weightClass}`}
        >
            {children || ''}
        </p>
    );
};

export default Paragraph;
