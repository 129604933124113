import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import * as d3 from 'd3';

import CalculatorContext from '../../contexts/CalculatorContext';

import ResultsChartTitle from '../../components/Results/ChartTitle';
import ResultsExamModal from '../../components/Results/ExamModal';
import ResultsMobileContent from '../../components/Results/MobileContent';
import NavButtons from '../../components/NavButtons';

import flower from '../../assets/flowers/results-flower-1.png';
import { HOME, CALCULATOR, EXTRAS } from '../../constants/routes';
import { createLinearScale } from '../../utils/charts';
import * as chart from '../../utils/resultsChart';
import data from '../../data/resultsChart.json';

import './styles.css';

const chartConfig = {
    height: 700,
    width: 1152,
    margin: { top: 50, right: 100, bottom: 25, left: 75 },
    bars: { area: 900, line: 1.5, large: 22.5, wide: 45 },
};

const yDomain = [0.005, d3.max(data, (d) => d.height)];
const yRange = [chartConfig.height * 0.85 - 20, chartConfig.margin.top];
chartConfig.yScale = createLinearScale(yDomain, yRange);

const chartFunctions = [
    (svg, results) => chart.addBaseComponents(svg, chartConfig, data, results),
    (svg) => chart.extendIconsLink(svg, chartConfig),
    (svg) => chart.showMonthBars(svg, chartConfig),
    (svg) => chart.showWeekBars(svg, chartConfig),
    (svg) => chart.showDayBars(svg, chartConfig),
    (svg, results) =>
        chart.showPregnancyOverview(svg, chartConfig, data, results),
    (svg, results) => chart.explainFirstDay(svg, chartConfig, data, results),
    (svg, results) =>
        chart.explainOvulationDay(svg, chartConfig, data, results),
    (svg, results) =>
        chart.explainPeriodDelayDay(svg, chartConfig, data, results),
    (svg, results) => chart.explainToday(svg, chartConfig, data, results),
    (svg, results) => chart.explainTermPeriod(svg, chartConfig, data, results),
    (svg, results) =>
        chart.explainTermPeriodLimits(svg, chartConfig, data, results),
    (svg, results) =>
        chart.explainExpectedBirthday(svg, chartConfig, data, results),
    (svg) => chart.createChartBase(svg, chartConfig, data),
    (svg) => chart.showHalfDistribution(svg, chartConfig, data),
    (svg) => chart.showDistribution(svg, chartConfig, data),
    (svg) => chart.explainTermEnd(svg, chartConfig, data),
    (svg) => chart.showFollowUpQuestion(svg),
];

const ResultsPage = () => {
    const { entries, results } = useContext(CalculatorContext);
    const [step, setStep] = useState(0);
    const [showToday, setShowToday] = useState(true);
    const [showExamModal, setShowExamModal] = useState(false);

    const svgReference = useRef(null);
    const navigate = useNavigate();

    if (
        chartFunctions.length === 18 &&
        results['G. Age'] &&
        (results['G. Age'] < 1 || results['G. Age'] > 300)
    ) {
        chartFunctions.splice(9, 1);
        setShowToday(false);
    }

    data.forEach((row) => {
        row.today = row.day === results['G. Age'] ? 'wide' : 'line';
        row.ovulation = row.day === entries['Cycle'] - 13 ? 'wide' : 'line';
        row.periodDelay = row.day === entries['Cycle'] + 1 ? 'wide' : 'line';

        if (
            row.day === entries['Cycle'] + 231 ||
            row.day === entries['Cycle'] + 265
        ) {
            row.termPeriodLimits = 'wide';
            row.expectedBirthday = 'large';
        } else if (
            row.day > entries['Cycle'] + 231 &&
            row.day < entries['Cycle'] + 265
        ) {
            row.termPeriodLimits = 'large';
            row.expectedBirthday = 'large';
        } else {
            row.termPeriodLimits = 'line';
            row.expectedBirthday = 'line';
        }

        if (row.day === entries['Cycle'] + 252) row.expectedBirthday = 'wide';
    });

    useEffect(() => {
        const svg = d3.select(svgReference.current);

        chartFunctions[step](svg, { ...entries, ...results });
    }, [step, entries, results]);

    const previousFunction = () =>
        step === 0
            ? navigate(CALCULATOR)
            : setTimeout(() => {
                  setStep(step - 1);
              }, 300);

    const nextFunction = () =>
        step < chartFunctions.length - 1
            ? setTimeout(() => {
                  setStep(step + 1);
              }, 300)
            : navigate(EXTRAS);

    let redirect = false;
    Object.values(results).forEach((v) => (redirect = redirect || !v));
    if (redirect) return <Navigate replace to={HOME} />;

    return (
        <div
            id='results-page'
            className={`step-${step}${showToday ? '' : '-hide-today'}`}
        >
            {showExamModal && (
                <ResultsExamModal setShowExamModal={setShowExamModal} />
            )}
            {(step === 17 || (!showToday && step === 16)) && (
                <div id='results-follow-up-wrapper'>
                    <main id='results-follow-up-content'>
                        <div className='results-follow-up-flower'>
                            <img src={flower} alt='' />
                        </div>
                        <section id='results-follow-up-question'>
                            <div>
                                Mas por que alguns bebês nascem tão antes e
                                outros tão depois da data provável do parto?
                            </div>
                            <div className='color-pink'>
                                É saudável meu bebê nascer em qualquer momento
                                do período de termo?
                            </div>{' '}
                            <div>
                                A resposta está em entrar em trabalho de parto
                                espontaneamente.
                            </div>
                        </section>
                        <div className='results-follow-up-flower'>
                            <img src={flower} alt='' />
                        </div>
                    </main>
                </div>
            )}
            <div id='results-page-content'>
                <ResultsChartTitle step={step} showToday={showToday} />
                <svg
                    id='results-svg'
                    ref={svgReference}
                    viewBox={[[0, 0, chartConfig.width, chartConfig.height]]}
                />
                <ResultsMobileContent
                    step={step}
                    showToday={showToday}
                    results={{ ...entries, ...results }}
                    setShowExamModal={setShowExamModal}
                />
            </div>
            <NavButtons
                pageName='results'
                previousFunction={previousFunction}
                nextFunction={nextFunction}
            />
        </div>
    );
};

export default ResultsPage;
