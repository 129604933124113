import React, { useContext } from 'react';

import CalculatorContext from '../../../contexts/CalculatorContext';

import HeadingTwo from '../../Heading/Two';
import Paragraph from '../../Paragraph';
import RegularButton from '../../Button/Regular';

import PregnantWomanImage from '../../../assets/photos/pregnancy-intro.png';

import './styles.css';

const CalculatorIntroView = () => {
    const { setStep, setMethod } = useContext(CalculatorContext);

    return (
        <div id='calculator-intro-view'>
            <div id='calculator-intro-image'>
                <img src={PregnantWomanImage} alt='' />
            </div>
            <main id='calculator-intro-content'>
                <HeadingTwo color='purple'>
                    De pesquisadoras da USP para as gestantes brasileiras
                </HeadingTwo>
                <Paragraph color='dark'>
                    Essa é uma calculadora um pouco diferente das demais: em vez
                    do dia exato do nascimento do seu bebê, você vai saber{' '}
                    <span className='weight-900'>
                        o período em que é mais provável que o trabalho de parto
                        inicie espontaneamente
                    </span>
                    , e o que essas datas significam para a saúde do seu bebê.
                </Paragraph>
                <section id='calculator-intro-details'>
                    <Paragraph color='purple' size='small'>
                        Para saber o período do provável nascimento do seu bebê,
                        você pode escolher um dos dois caminhos:
                    </Paragraph>
                </section>
                <section id='calculator-intro-buttons'>
                    <RegularButton
                        fill='lime'
                        text='data da última menstruação'
                        onClick={() => {
                            setMethod('Last Period Date');
                            setStep('form');
                        }}
                    />
                    <RegularButton
                        fill='white-dark'
                        text='data do primeiro ultrassom'
                        onClick={() => {
                            setMethod('First Exam Date');
                            setStep('form');
                        }}
                    />
                </section>
            </main>
        </div>
    );
};

export default CalculatorIntroView;
