import React from 'react';

import './styles.css';

const NumberInput = ({
    label,
    minimum,
    maximum,
    sideText,
    value,
    width,
    onValueChange,
}) => {
    const widthClass = width ? `number-input-width-${width}` : '';

    return (
        <div className={`number-input-wrapper`}>
            <div className={`number-input-inner-wrapper ${widthClass}`}>
                <input
                    type='number'
                    className={`number-input ${widthClass}`}
                    min={minimum.toString()}
                    max={maximum.toString()}
                    value={value || 0}
                    onChange={onValueChange}
                />
                {sideText && (
                    <span className='number-input-side-text'>{sideText}</span>
                )}
            </div>
            {label && <span className='number-input-label'>{label}</span>}
        </div>
    );
};

export default NumberInput;
