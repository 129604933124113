import React from 'react';

import './styles.css';

const RegularButton = ({ border, fill, text, width, onClick }) => {
    const borderClass = border ? `button-border-${border}` : '';
    const fillClass = fill ? `button-fill-${fill}` : '';
    const widthClass = width ? `button-width-${width}` : '';

    return (
        <button
            className={`${borderClass} ${fillClass} ${widthClass}`}
            onClick={onClick}
        >
            {text || ''}
        </button>
    );
};

export default RegularButton;
