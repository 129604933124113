import React, { useContext } from 'react';

import CalculatorContext from '../../contexts/CalculatorContext';

import CalculatorIntroView from '../../components/Calculator/IntroView';
import CalculatorFormView from '../../components/Calculator/FormView';
import CalculatorResultsView from '../../components/Calculator/ResultsView';

import './styles.css';

const CalculatorPage = () => {
    const { step, setStep } = useContext(CalculatorContext);

    switch (step) {
        case 'introduction':
            return <CalculatorIntroView />;
        case 'form':
            return <CalculatorFormView />;
        case 'results':
            return <CalculatorResultsView />;
        default:
            setStep('introduction');
            return <CalculatorIntroView />;
    }
};

export default CalculatorPage;
