import React from 'react';

import '../Regular/styles.css';
import './styles.css';

const IconButton = ({ border, fill, icon, position, text, width, onClick }) => {
    const borderClass = border ? `button-border-${border}` : '';
    const fillClass = fill ? `button-fill-${fill}` : '';
    const iconClass = icon ? `button-icon-${icon}` : '';
    const iconPosition = position || 'center';
    const widthClass = width ? `button-width-${width}` : '';

    return (
        <button
            className={`icon-button ${borderClass} ${fillClass} ${widthClass}`}
            onClick={onClick}
        >
            {iconPosition === 'left' && (
                <span className={`left-icon ${iconClass}`}></span>
            )}
            {iconPosition === 'center' && (
                <span className={`center-icon ${iconClass}`}></span>
            )}
            {text || ''}
            {iconPosition === 'right' && (
                <span className={`right-icon ${iconClass}`}></span>
            )}
        </button>
    );
};

export default IconButton;
