import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

import { createLinearScale } from '../../../utils/charts';
import * as chart from '../../../utils/birthsByDayChart';
import data from '../../../data/distributionCharts.json';

import './styles.css';

const chartConfig = {
    height: 700,
    width: 1152,
    margin: { top: 50, right: 100, bottom: 25, left: 75 },
    bars: { area: 900, line: 1.5, large: 22.5, wide: 45 },
};

const yDomain = [0.005, d3.max(data, (d) => d.value)];
const yRange = [chartConfig.height * 0.85 - 20, chartConfig.margin.top];
chartConfig.yScale = createLinearScale(yDomain, yRange);

const chartFunctions = [
    (svg) => chart.addBaseComponents(svg, chartConfig, data),
    (svg) => chart.showRealDistribution(svg, chartConfig),
    (svg) => chart.highlightHalfTermPeriod(svg, chartConfig),
];

const ExtrasBirthsByDayView = ({ step }) => {
    const svgReference = useRef(null);

    useEffect(() => {
        const svg = d3.select(svgReference.current);

        if (step === 1) chartFunctions[0](svg);
        if (step === 2) chartFunctions[1](svg);
        if (step === 3) {
            chartFunctions[0](svg);
            chartFunctions[2](svg);
        }
    }, [step]);

    return (
        <div id='extras-births-by-day-view'>
            <header
                id='extras-births-by-day-chart-title'
                title='Dados de nascimentos no Brasil em 2019, obtidos a partir do SINASC (Sistema de Informação sobre Nascidos Vivos)'
            >
                Gráfico: Nascimentos a termo por dia de gestação
            </header>
            <svg
                id='births-by-day-chart-svg'
                ref={svgReference}
                viewBox={[[0, 0, chartConfig.width, chartConfig.height]]}
            />
            <section id='births-by-day-chart-mb-content'>
                {step === 1 && (
                    <span>
                        O nascimento dos bebês no período de termo{' '}
                        <span className='weight-900'>deveria</span> se
                        distribuir assim:{' '}
                        <span className='weight-900'>
                            bem poucos no início, muitos próximo à data provável
                            do parto (DPP) e mais alguns no final
                        </span>
                        .
                    </span>
                )}
                {step === 2 && (
                    <span>
                        Quando olhamos todos os partos no Brasil, eles se
                        distribuem de um jeito bem diferente:{' '}
                        <span className='weight-900'>
                            há muitos partos antes da data provável do parto
                            (DPP)
                        </span>{' '}
                        e apenas 31% depois, quando deveriam ser 40%.
                    </span>
                )}
                {step === 3 && (
                    <span>
                        Isso acontece pela prática frequente no Brasil de{' '}
                        <span className='weight-900'>
                            intervir para antecipar o nascimento,
                        </span>{' '}
                        seja através de{' '}
                        <span className='weight-900'>cesárea agendada</span> ou
                        de <span className='weight-900'>indução</span> logo que
                        a gestação atinge a DPP.
                    </span>
                )}
            </section>
        </div>
    );
};

export default ExtrasBirthsByDayView;
